/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
const deLang = {
  student: 'Schüler',
  id_check_alert: 'Kunde: {{customer}}. Bitte überprüfen Sie die folgenden Kunden-IDs: {{id}}.',
  id_check: 'ID-Prüfung',
  cart: 'Warenkorb',
  item: 'Artikel',
  items: 'Artikel',
  subtotal: 'Zwischensumme',
  discount: 'Rabatt',
  total: 'Gesamt',
  pay: 'Bezahlen',
  card: 'Mit Karte',
  cash: 'Bar',
  invoice: 'Auf Rechnung',
  giftcard: 'Mit Gutschein',
  countr_giftcard: 'Mit Countr Gutschein',
  seqr: 'SEQR',
  bitpay: 'Mit Bitpay',
  payment_method: 'Zahlungsart',
  cash_received: 'Betrag erhalten',
  or_enter_amount: 'Oder Betrag eingeben',
  payment_succeeded: 'Zahlung erfolgreich',
  change_due: 'Wechselgeld',
  total_paid: 'Bezahlt gesamt',
  send_receipt: 'Beleg senden',
  send_invoice: 'Rechnung senden',
  send: 'Senden',
  delete: 'Löschen',
  new_sale: 'Neuer Verkauf',
  custom_price: 'Sonderpreis',
  item_description: 'Artikelbeschreibung',
  products: 'Artikel',
  transactions: 'Transaktionen',
  reports: 'Berichte',
  report: 'Bericht',
  settings: 'Einstellungen',
  sign_out: 'Abmelden',
  receipt: 'Beleg',
  refund: 'Rückerstattung',
  home: 'Startseite',
  clear: 'Löschen',
  add: 'Hinzufügen',
  sign_up: 'Registrieren',
  sign_up_email: 'Mit E-Mail registrieren',
  sign_in: 'Anmelden',
  company_name: 'Unternehmen',
  merchant_name: 'Kontaktperson',
  email_address: 'E-Mail-Adresse',
  phone_number: 'Telefonnummer',
  password: 'Passwort',
  confirm_password: 'Passwort bestätigen',
  forgot_password_question: 'Passwort vergessen?',
  next: 'Weiter',
  demo: 'Demo',
  skip: 'Überspringen',
  skip_registration: 'Registrierung überspringen',
  take_a_tour: 'Demo Version',
  items_returned: 'Artikel zurückgegeben',
  wrong_billing: 'Verrechnungsfehler',
  order_cancelled: 'Bestellung storniert',
  other: 'Sonstiges',
  enter_valid_amount: 'Gültige Anzahl eingeben',
  invalid_merchant_name: 'Kontaktperson nicht vorhanden',
  invalid_business_name: 'Unternehmen nicht vorhanden',
  invalid_email_address: 'E-Mail-Adresse nicht vorhanden',
  invalid_phone_number: 'Telefonnummer nicht vorhanden',
  invalid_password: 'Passwort muss mindestes 8 Zeichen haben',
  password_mismatch: 'Passwort falsch',
  coming_soon: 'Bald verfügbar ...',
  to_use_signup: 'Funktion nur für registrierte Nutzer',
  login_error: 'Fehler bei der Anmeldung: bitte Anmeldeinformation und Internetverbindung prüfen',
  signup_error: 'Fehler bei der Registrierung',
  transaction_history_empty: 'Transaktionsverlauf ist leer',
  categories: 'Kategorien',
  all_products: 'Alle Produkte',
  search_product: 'Produkt suchen',
  reissue_receipt: 'Beleg neu ausstellen',
  print_receipt: 'Beleg drucken',
  or_send_receipt: 'Oder Beleg senden',
  save: 'Speichern',
  saved: 'Gespeichert',
  search: 'Suchen',
  custom_item: 'Sonderartikel',
  submit: 'Anmelden',
  submitted: 'Angemeldet',
  note: 'Notiz',
  message: 'Nachricht',
  amount: 'Betrag',
  number: 'Nummer',
  taxes: 'Steuern',
  tax: 'Steuer',
  enter_valid_number: 'Gültige Zahl eingeben',
  enter_valid_text: 'Gültigen Text eingeben',
  enter_valid_phone: 'Gültige Telefonnummer eingeben',
  enter_valid_email: 'Gültige E-Mail-Adresse eingeben',
  feature_not_in_demo: 'Funktion in der Demoversion nicht verfügbar',
  provide_refund_reason: 'Sie müssen einen Grund für die Retoure angeben',
  provide_valid_refund_amount: 'Gültiger Rückerstattungsbetrag muss eingegeben werden',
  refund_exceed_refundable: 'Rückerstattungsbetrag ist höher als die Rechnung!',
  refund_saved: 'Rückerstattung gespeichert',
  or_customize_discount: 'Oder Rabatt anpassen',
  enter_percentage: 'Prozent eingeben',
  manual_card_payment: 'Manuelle Kartenzahlung',
  did_card_payment_succeed: 'Kartenzahlung erfolgreich?',
  manual_card_refund: 'Manuelle Rückerstattung mit Karte',
  did_card_refund_succeed: 'Rückerstattung mit Karte erfolgreich?',
  giftcard_refund: 'Rückerstattung Gutschein',
  did_giftcard_refund_succeed: 'Neuen Gutschein ausgestellt?',
  yes: 'Ja',
  no: 'Nein',
  general: 'Allgemein',
  printer: 'Drucker',
  barcode_scanner: 'Barcode-Scanner',
  payment: 'Zahlung',
  profile: 'Konto',
  currency: 'Währung',
  printing_receipt: 'Beleg wird gedruckt ...',
  enter_amount: 'Betrag eingeben',
  expected_change: 'Wechselgeld',
  still_remaining: 'Noch offen',
  issue_invoice: 'Rechnung ausstellen',
  URL_pricing: 'http://www.countrhq.com/#pricing',
  address: 'Adresse',
  address_continued: 'Adresse (optional)',
  city: 'Stadt',
  zip: 'Postleitzahl',
  country: 'Land',
  country_iso: 'Landeskennung laut ISO 3166-2 (z. B. "nl", "de", "gb")',
  invalid_address: 'Ungültige Adresse',
  invalid_city: 'Ungültige Stadt',
  invalid_country: 'Ungültiges Land',
  invalid_ecommerce: 'Keine E-Commerce-Plattform gewählt',
  signup_error_contact_support: 'Fehler bei der Registrierung: bitte support@countrhq.com kontaktieren',
  open_orders: 'Offene Bestellungen',
  order: 'Bestellung',
  enter_weight_g: 'Gewicht eingeben (Gramm)',
  search_scanner: 'Socket CHS Scanner suchen',
  order_settings: 'Einstellungen',
  order_amount: 'Offene Bestellungen',
  receipts: 'Belege',
  print_receipts: 'Belege automatisch drucken',
  open_cash_drawer: 'Kassenlade automatisch öffnen',
  backup: 'Backup',
  email_backup: 'E-Mail-Backup',
  server_sync: 'Server-Synchronisierung',
  external_payment: 'Sonstiges',
  search_printer: 'Drucker suchen',
  sms_successfully_sent: 'SMS erfolgreich gesendet',
  error_sending_sms: 'SMS nicht gesendet, später erneut versuchen',
  email_successfully_sent: 'E-Mail erfolgreich gesendet',
  error_sending_email: 'E-Mail nicht gesendet, später erneut versuchen',
  transaction: 'Transaktion',
  logging_in: 'Anmeldung läuft ...',
  downloading_products: 'Produktdownload läuft ...',
  add_to_cart: 'Hinzufügen',
  invalid_price: 'Ungültiger Preis',
  invalid_discount: 'Ungültiger Rabatt',
  invalid_new_price: 'Ungültiger Preis (Preis muss kleiner als Originalpreis sein)',
  invalid_amount: 'Ungültige Anzahl',
  edit_price: 'Preis ändern',
  discount_perc: 'Rabatt (%)',
  quantity: 'Menge',
  remove: 'Entfernen',
  sales_info: 'Verkaufsinfo',
  summary: 'Zusammenfassung',
  refunded_information: 'Rückerstattungsinfo',
  total_sale: 'Gesamtbetrag',
  total_amount_refunded: 'Rückerstattet gesamt',
  remaining: 'Rest',
  sold_by: 'Verkauft von',
  source_receipt_number: 'Quellbelegnummer',
  issued_date: 'Erstellungs Datum',
  issued_time: 'Erstellungs Zeit',
  refunded_by: 'Erstattet von',
  reason: 'Grund',
  receipt_number: 'Belegnummer',
  refunded_date: 'Retoure Datum',
  refunded_time: 'Retoure Zeit',
  first_name: 'Vorname',
  last_name: 'Nachname',
  shop_category: 'Shop-Kategorie',
  choose_country: 'Land wählen ...',
  you_will_receive_email: 'Registrierung erfolgreich',
  wait_email: 'Sie erhalten in Kürze eine E-Mail mit einer Anleitung für die Verbindung Ihrer E-Commerce-Plattform mit Countr POS.',
  open_dashboard: 'Sie können nun Ihr Konto verwalten und auf Ihrem persönlichen Countr Dashboard Produkte erstellen.',
  by_signing_up: 'Mit meiner Registrierung akzeptiere ich die',
  terms_and_conditions: 'Allgemeinen Geschäftsbedingungen',
  and: 'und',
  privacy_policy: 'Datenschutzrichtlinien',
  refund_items: 'Artikel zurücknehmen',
  forgot_password: 'Passwort vergessen?',
  register_device: 'Gerät registrieren',
  device_not_registered: 'Dieses Gerät ist nicht registriert',
  want_to_add_to_account: 'Jetzt registrieren?',
  if_ios_previously_registered: 'Sollten Sie Countr vor Kurzem neu installiert haben, müssen Sie auch Ihr Gerät neu registrieren.',
  new_device_info: 'Im nächsten Schritt werden Sie aufgefordert, Ihr Gerät mit einem Ihrer Standorte zu verknüpfen.',
  not_now: 'Nicht jetzt',
  proceed: 'Weiter',
  more_info_click: 'Mehr Info? Hier klicken',
  your_detail: 'Ihre Daten',
  vat_no: 'UID',
  billing_details: 'Zahlungsdaten',
  add_store: 'Standort hinzufügen',
  or_add_to_existing_store: 'Oder zu bestehendem Standort hinzufügen',
  add_new_store: 'Neuen Standort hinzufügen',
  store_information: 'Standortdaten',
  store_name: 'Standortname',
  go_to_web_dashboard: 'Zum Web-Dashboard',
  error_create_account: 'Fehler bei der Kontoerstellung',
  error_creation_error: 'Bei der Registrierung ist ein Fehler aufgetreten',
  error_wrong_phone_number: 'Bitte gültige Telefonnummer eingeben',
  error_try_again: 'Netzwerkproblem, später erneut probieren',
  error_from_the_server: 'Keine Verbindung zum Server.',
  error_update_cc_info: 'Kreditkartendaten können nicht aktualisiert werden',
  error_add_device: 'Gerät kann dem aktuellen Standort nicht hinzugefügt werden',
  error_add_store: 'Neuer Standort kann nicht hinzugefügt werden',
  are_not_valid: 'sind ungültig',
  is_not_valid: 'ist ungültig',
  are_empty: 'sind leer',
  is_empty: 'ist leer',
  are_not_selected: 'wurden nicht ausgewählt',
  is_not_selected: 'wurde nicht ausgewählt',
  bill: 'Rechnung',
  URL_dashboard: 'https://backoffice.countrhq.com/',
  URL_support: 'mailto:support@countrhq.com',
  URL_forgot_password: 'https://backoffice.countrhq.com/#/auth/forgot',
  close: 'Schließen',
  no_product_found: 'Keine Produkte gefunden',
  please_go_to_dashboard: 'Um Produkte hinzuzufügen, besuchen sie: https://backoffice.countrhq.com',
  select_interface: 'Wählen Sie die Schnittstelle',
  refund_all: 'alle Rückerstattungen',
  purchased_items: 'gekaufte Artikel',
  refundable: 'Zurückerstattbar',
  to_be_refunded: 'Zum erstatten',
  out_of: 'Aus',
  enter_a_printer_name: 'Bitte Druckername eingeben',
  cart_is_empty: 'Warenkorb ist leer',
  searching_printers: 'Druckersuche läuft ...',
  no_printers_found: 'Keine Drucker gefunden: für die Suche nach Bluetooth-Druckern, Bluetooth am Gerät aktivieren',
  total_due: 'Zu bezahlen',
  payment_not_successful: 'Zahlung fehlgeschlagen',
  error_processing_payment: 'Fehler in der Verarbeitung',
  purchase: 'Einkauf',
  transaction_aborted: 'Transaktion abgebrochen',
  authorization_error: 'Fehler bei der Autorisierung',
  authorization_ok: 'Autorisierung erfolgreich',
  transaction_cancelled: 'Transaktion storniert',
  clearance_error: 'Fehler bei der Freigabe durch Zahlungsanbieter',
  clearance_ok: 'Freigabe durch Zahlungsanbieter erfolgreich',
  transaction_declined: 'Transaktion abgelehnt',
  transaction_complete: 'Transaktion erfolgreich',
  initiating: 'Initialisierung läuft ...',
  application_selected: 'Anwendung ausgewählt',
  scheduled_for_clearance: 'Offline-Freigabe durch Zahlungsanbieter eingeleitet',
  terminal_error: 'Terminalfehler',
  unknown_status: 'Status unbekannt',
  waiting_for_clearance: 'Warte auf Offline-Freigabe durch Zahlungsanbieter',
  amount_exceeded: 'Höchstbetrag überschritten',
  authorization_timeout: 'Autorisierung dauert zu lange',
  batch_processing_failed: 'Batch-Verarbeitung fehlgeschlagen',
  cancelled: 'Storniert',
  card_blocked: 'Karte gesperrt',
  card_invalid: 'Karte ungültig',
  card_declined: 'Karte abgelehnt',
  transaction_failed: 'Transaktion fehlgeschlagen',
  insufficient_funds: 'Saldo zu gering',
  terminal_not_paired: 'Terminal nicht verbunden: Terminal neustarten',
  pin_incorrect_last_retry: 'PIN falsch (letzter Versuch)',
  pin_incorrect_retry: 'PIN falsch (weiterer Versuch)',
  printer_busy: 'Drucker besetzt',
  printer_error: 'Fehler beim Drucken',
  printer_not_present: 'Drucker nicht bereit',
  printer_out_of_paper: 'Papier im Drucker nachfüllen',
  success: 'Erfolgreich',
  system_error: 'Systemfehler',
  transaction_not_found: 'Transaktion nicht gefunden',
  unknown_device: 'Gerät unbekannt',
  apply_discount: 'Rabatt abziehen',
  seqr_id: 'SEQR Merchant ID',
  seqr_password: 'SEQR Merchant Passwort',
  bitpay_api_token: 'Bitpay API Token',
  registration_successful: 'Terminal erfolgreich registriert',
  unregistration_successful: 'Terminal erfolgreich abgemeldet',
  payment_init_not_successful: 'Zahlung nicht gestartet (Netzwerkverbindung prüfen)',
  always_show_addons: 'Add-ons immer anzeigen',
  show_product_image: 'Produktbild anzeigen',
  hide_product_img: 'Produktbild ausblenden',
  settings_connected_printers: 'Verbundene Drucker',
  settings_add_new_printer: 'Neuen Drucker hinzufügen',
  wifi_lan: 'WiFi oder LAN',
  bluetooth: 'Bluetooth',
  usb: 'USB',
  paired_barcode_scanners: 'Verbundene Barcode-Scanner',
  account: 'Konto',
  refresh_products: 'Produkte aktualisieren',
  server_synchronisation: 'Server-Synchronisierung',
  sales: 'Verkäufe',
  today: 'Heute',
  refunds: 'Rückerstattungen',
  product: 'Produkt',
  product_by_category: 'Produkte nach Kategorien',
  see_categories_details: 'Kategorieinfos anzeigen',
  open_orders_finish_payment_or_empty: 'Es sind noch Bestellungen offen: Bezahlung finalisieren oder Bestellungen löschen',
  name: 'Name',
  loading_products: 'Produkte werden geladen',
  you_should_select_at_least_one_item: 'Mindestens einen Artikel muss ausgewählt werden',
  select_card_provider: 'Kartenanbieter wählen',
  user_cancelled: 'Bezahlung von Kunde abgebrochen',
  card_payment: 'Kartenzahlung',
  manage_products: 'Produkte verwalten',
  edit_mode: 'Backoffice',
  lock: 'Sperren',
  loyalty: 'Loyalität',
  account_card: 'Kontokarte',
  account_lookup: 'Kontosuche',
  Activate: 'Aktivieren',
  add_anonymous_customer: 'Anonymen Kunden hinzufügen',
  add_customer: 'Kunde hinzufügen',
  add_customer_to_cart: 'Kunde wählen',
  add_new_account: 'Neues Konto hinzufügen',
  add_new_customer: 'Neuen Kunden hinzufügen',
  add_note_hint: 'Notiz hinzufügen',
  add_tax_to_price: 'Preise exkl. Steuern',
  add_tip: 'Trinkgeld hinzufügen',
  add_to_store: 'Standort hinzufügen',
  addons: 'Add-ons',
  admin_pin: 'Admin-PIN',
  admin_pin_saved: 'Neue Admin-PIN gespeichert',
  admin_pin_should_be: 'Admin-PIN sollte eine 4- bis 6-stellinge Zahl sein',
  aid: 'AID',
  aidCard: 'aidCard',
  allow_cart_discounts: 'Rabatte auf Bestellebene zulassen',
  allow_custom_cart_discounts: 'Sonderrabatte auf Bestellebene zulassen',
  allow_custom_items: 'Sonderartikel zulassen',
  allow_product_discounts: 'Rabatte auf Produktebene zulassen',
  allow_custom_product_discounts: 'Sonderrabatte auf Produktebene zulassen',
  amount_hint: '999',
  app_version: 'Version',
  applicationLabel: 'applicationLabel',
  applicationName: 'applicationName',
  applicationPreferredName: 'applicationPreferredName',
  appName: 'appName',
  ask_tips: 'Trinkgeld zulassen',
  authorizationCode: 'authorizationCode',
  available_points: 'Verfügbare Punkte',
  available_rewards: 'Verfügbare Prämien',
  back: 'Zurück',
  barcode_scanner_disconnected: 'Barcode-Scanner verbunden, doch möglicherweise ausgeschalten: bitte einschalten',
  barcode_scanner_not_found: 'Kein Barcode-Scanner gefunden',
  barcode_scanner_not_found_desc: 'Neuen Barcode-Scanner verbinden: Bluetooth in den Geräteeinstellungen aktivieren und nach einem der Scanner unten suchen',
  bunq: 'bunq',
  bunq_admin_approval: 'Ein Administrator von bunq muss Ihre Registrierkasse vor der Verwendung prüfen und freigeben',
  bunq_instructions: '1) Öffnen bunq App und PIN eingeben (falls erforderlich) \n2a) Android: Öffnen Sie links im Menü und wählen Sie "Scan QR Code" \n2b) iOS: Wählen Sie "Scan QR Code" aus dem Hauptbildschirm \n3) Scannen Sie den QR-Code \n4) eine Gratifikation hinzufügen, wenn gewünscht \n5) Zahlung bestätigen',
  can_only_refund_entire_amount: 'Bei {{payment_method}} kann nur der Gesamtbetrag am Beleg erstattet werden',
  cancel: 'Stornieren',
  cancel_payment: 'Zahlung stornieren',
  cannot_pay_later_more_than_remaining_value: 'Bei "Später bezahlen" kann nur der Restbetrag ({{amount}}) ausbezahlt werden',
  cannot_refund_transaction_with_partial_postponed_payments: 'Diese Transaktion enthält eine oder mehrere zeitlich verzögerte Teilzahlungen: Transaktion begleichen oder Gesamtbetrag erstatten',
  cant_find_products: 'Nicht alle Produkte am POS gefunden? Suchfeld nutzen oder Barcode scannen',
  card_scheme: 'Kartensystem',
  cardBrand: 'cardBrand',
  cardExpiryMonth: 'Gültig bis MM',
  cardExpiryYear: 'Gültig bis YY',
  cardType: 'cardType',
  cart_is_frozen_finish_payment: 'Bestellung gesperrt: vor nächstem Schritt Zahlung abschließen',
  cash_difference: 'Unterschied',
  cash_drawer: 'Kassenlade',
  cash_in: 'Bargeld ein',
  cash_in_out: 'Bargeld ein/aus',
  cash_out: 'Bargeld aus',
  change: 'Wechselgeld',
  change_currency: 'Währung ändern',
  change_given: 'Wechselgeld ausbezahlt',
  change_open_orders_amount: 'Betrag der offenen Bezahlung ändern',
  check_location_settings: 'Standorteinstellung prüfen',
  check_network: 'Netzwerkverbindung prüfen',
  choose_category: 'Unternehmenskategorie wählen',
  choose_mobile_provider: 'Mehrere Anbieter aktiviert: einen wählen',
  clear_settings: 'Einstellungen löschen',
  clear_settings_successful: 'Einstellungen erfolgreich gelöscht',
  clear_settings_warning: 'diese Einstellungen löschen Ihre Verbindungsinformationen zurückgesetzt. Klicken Sie bestätigen, um fortzufahren.',
  client_ticket: 'clientTicket',
  close_shift: 'Kasse schließen',
  company_address: 'Unternehmensadresse',
  company_registration_no: 'Registrierungsnummer des Unternehmens',
  completed_payments: 'Abgeschlossene Zahlungen',
  confirm: 'Bestätigen',
  confirm_cash_payment: 'Zahlungsbetrag bestätigen',
  confirm_new_currency: 'Währungsänderung bestätigen',
  confirm_open_orders_amount_change: 'Geänderten Betrag der offenen Bestellungen bestätigen?',
  confirm_payment: 'Zahlung bestätigen',
  confirm_with_change: 'Mit Änderung bestätigen',
  confirm_with_tip: 'Mit Trinkgeld bestätigen',
  connect_barcode: 'Barcode-Scanner (Socket CHS) verbinden',
  connect_to_webshop: 'Mit Onlineshop verbinden?',
  connected_printer: 'Verbundene Drucker',
  contact_information: 'Kontaktinformation',
  contact_us: 'support@countrhq.com',
  create_product: 'Produkt erstellen',
  currently_trial_mode_transaction_wiped_visit_dashboard: 'POS befindet sich im Testmodus: Transaktionen werden alle vier Stunden gelöscht',
  currently_trial_mode_visit_dashboard: 'POS befindet sich im Testmodus: zum Wechsel in den Livemodus, Online-Dashboard öffnen',
  customer: 'Kunde',
  customer_card: 'Kundenkarte',
  customer_info: 'Kundendaten',
  customer_instructions: 'Kundenanweisungen',
  customer_lookup: 'Kundensuche',
  customer_lookup_error: 'Serverfehler bei Kundensuche',
  customer_name: 'Kundenname',
  customer_not_valid: 'Kunde ungültig',
  customer_receipt: 'Kundenbeleg',
  customer_since: 'Kunde seit',
  Date: 'Datum',
  deregister_terminal: 'Gerät abmelden',
  description_mandatory: 'Beschreibung für eingehende und ausgehende Bargeldtransaktionen verpflichtend',
  device_locked: 'Gerät gesperrt',
  device_locked_enter_pin: 'Dieses Gerät ist gesperrt.\nZum Entsperren Admin-PIN eingeben',
  did_cheque_refund_succeed: 'Scheck zurückgegeben?',
  did_mobile_payment_succeed: 'Handy-Zahlung erfolgreich?',
  did_mobile_refund_succeed: 'Rückerstattung per Handy erfolgreich?',
  direct_connection: 'Direkt verbunden',
  disabled_product: 'Deaktiviertes Produkt',
  discount_currency: 'Rabatt ({{currency}})',
  do_you_want_remove_payment: 'Zahlung löschen?',
  download_adyen_app: 'Adyen App herunterladen',
  download_izettle_app: 'iZettle App herunterladen',
  download_payleven_app: 'Payleven App herunterladen',
  download_sumup_app: 'SumUp App herunterladen',
  download_yodo_app: 'Yodo Rocket App herunterladen',
  downloading_categories: 'Kategorie-Download läuft ...',
  downloading_taxes: 'Steuer-Download läuft ...',
  downloading_transactions: 'Transaktions-Download läuft ...',
  ecommerce_none: 'Ich erstelle Produkte selbst',
  ecrId: 'ecrId',
  eft_transaction_code: 'EFTTxnID',
  email_invoice: 'E-Mail-Rechnung',
  employee_name: 'Mitarbeiter',
  employee_pin: 'Mitarbeiter-PIN',
  empty_cart: 'Leere Bestellung',
  enable_staff_pin: 'Mitarbeiter-PIN-Anmeldung aktivieren',
  enter_a_valid_search_value: 'Gültigen Suchbegriff eingeben',
  enter_api_key: 'Aktivierungscode eingeben',
  enter_base_url: 'URL eingeben (inkl. "http://")',
  enter_merchant_auth_code_payconiq: 'Payconiq Autorisierungs-Header eingeben',
  enter_merchant_iban_payconiq: 'Bei Payconiq registrierte IBAN eingeben',
  enter_merchant_id_payconiq: 'Payconiq Merchant ID eingeben',
  enter_order_name: 'Name der Bestellung eingeben',
  enter_reseller_id: 'Reseller ID eingeben',
  enter_user_number: 'User-Nr. eingeben',
  enter_user_password: 'User-Passwort eingeben',
  error_adding_device: 'Fehler während Geräteregistrierung',
  error_refreshing_merchant: 'Fehler beim Abrufen von Kontodaten',
  error_retrieve_categories: 'Fehler beim Abrufen der Kategorien.\nBitte Internetverbindung prüfen und erneut versuchen',
  error_retrieve_products: 'Fehler beim Abrufen der Produkte.\nBitte Internetverbindung prüfen und erneut versuchen',
  error_retrieve_taxes: 'Fehler beim Abrufen der Steuern.\nBitte Internetverbindung prüfen und erneut versuchen',
  error_while_retrieving_results: 'Fehler beim Abrufen der Ergebnisse',
  errorCode: 'errorCode',
  errorMessage: 'errorMessage',
  ersReference: 'ersReference',
  excess_amount: 'Überbezahlung',
  expected_amount: 'Voraussichtlicher Betrag',
  expected_change_gratuity: 'Voraussichtliches Wechsel- oder Trinkgeld',
  external_url: 'Externe URL',
  failed_cancel: 'Transaktionsabbruch fehlgeschlagen',
  feature_only_android: 'Funktion nur für Android verfügbar',
  find_terminal: 'Terminals suchen',
  for_security_enter_password: 'Aus Sicherheitsgründen Passwort erneut eingeben',
  for_this_device: 'Nur für dieses Gerät',
  for_this_store: 'Nur für diesen Standort',
  four_inch: '4 Inch',
  generate_keys: 'Keys erstellen',
  generating_keypair: 'Kaypair wird erstellt ...\nDieser Vorgang kann einige Minuten dauern',
  generating_reports: 'Bericht wird erstellt ...',
  generic_usb: 'Herkömmlicher USB',
  get_your_free_pos: 'POS auf www.countrhq.com sichern',
  gift_code: 'Gutscheincode',
  gratuity_amount: 'Trinkgeld',
  gratuity_percentage: 'Trinkgeld in Prozent',
  group_items_in_cart: 'Gleiche Artikel in Warenkorb zusammenführen',
  shared_secret: 'SharedSecret',
  impossible_refresh_you_have_transactions: '{{transaction_number}} Transaktion(en) nicht mit dem Server synchronisiert.\nSynchronisierungsversuch läuft im Hintergrund ...',
  impossible_signout_you_have_transactions: 'Abmelden nicht möglich.\n{{transaction_number}} Transaktion(en) nicht mit dem Server synchronisiert.\nSynchronisierungsversuch läuft im Hintergrund ...',
  insert_printer_name: 'Druckernamen eingeben (mindestens 3 Zeichen)',
  inserted_login_not_valid: 'Anmeldedaten falsch',
  inserted_value_not_valid: 'Eingefügter Wert ist ungültig',
  installmentAmount: 'installmentAmount',
  invalid_credit_card_number: 'Kreditkartennummer muss 16-stellig sein',
  invoiceReference: 'invoiceReference',
  invoiceStatus: 'invoiceStatus',
  ip_address: 'IP Adresse',
  is_business: 'Unternehmensdaten',
  is_correct_amount_tendered: 'Ist {{amount}} korrekt?',
  isDone: 'Laden beendet',
  isError: 'Tippen und erneut versuchen ...',
  isTap: 'Tippen und mehr laden ...',
  join_thousands_of_merchants: 'Tausende Händler nutzen bereits Countr. Jetzt seid ihr dran. Gleich kostenlos registrieren',
  language: 'Sprache',
  last_visit: 'Letzter Besuch',
  leave_tour: 'Erste Schritte überspringen',
  leaving_tour: 'Erste Schritte werden übersprungen ...',
  loyal_customer: 'Treuer Kunde',
  mailing_list_permission: 'Zur Mailingliste hinzufügen',
  manual_mobile_payment: 'Handy-Zahlung',
  manual_mobile_refund: 'Handy-Rückerstattung',
  max_amount_exceeded: 'Zu hoher Betrag für diese Zahlungsmethode',
  menu_item_12char: 'Menüartikelname darf maximal 12 Zeichen enthalten',
  menu_item_name: 'Menüartikelname (max. 12 Zeichen)',
  merchant_receipt: 'Händlerbeleg',
  merchantDisplayName: 'merchantDisplayName',
  merchantId: 'merchantID',
  mid: 'MID',
  mobile_number: 'Handynummer',
  mobile_payment: 'Handy',
  mobile_payments: 'Handy-Zahlung',
  new_price: 'Neuer Preis ({{currency}})',
  no_available_addons_for_product: 'Für das gewählte Produkt sind keine Add-ons verfügbar',
  no_connected_printer: 'Kein Drucker verbunden',
  no_connected_terminal: 'Kein/e Terminal/s verbunden',
  no_customer_found: 'Kein Kunde gefunden',
  no_customer_selected: 'Kein Kunde ausgewählt',
  no_printer_configured: 'Kein Drucker eingerichtet',
  no_printer_found: 'Drucker nicht gefunden\nFür via USB verbundene Star Drucker hier klicken',
  no_product_in_cart: 'Bestellung enthält kein Produkt',
  no_terminals_found: 'Keine Terminals gefunden',
  no_url_specified: 'Fehler, keine URL angegeben.',
  none: 'Sonstiges',
  note_mandatory_cash_difference: 'Im Fall einer Differenz zwischen voraussichtlichem und tatsächlichem Betrag muss eine Notiz hinzugefügt werden',
  note_optional: 'Notiz (optional)',
  numberOfInstallments: 'numberOfInstallments',
  numpad: 'Numpad',
  offline: 'Offline',
  ok: 'OK',
  one_or_more_carts_are_frozen_finish_payment: 'Eine Bestellung oder mehrere gesperrt: vor nächstem Schritt Zahlung abschließen',
  online: 'Online',
  open_market: 'Markt öffnen?',
  open_register_before_transaction: 'Vor jeder Transaktion muss die Kasse geöffnet werden',
  open_shift: 'Kasse öffnen',
  order_printer: 'Drucker für Bestellungen',
  orderId: 'orderID',
  orderRef: 'orderRef',
  other_text: 'Anderer Text',
  paid: 'Bezahlt',
  paid_so_far: 'Bereits bezahlt',
  paid_with_method: 'Bezahlt mit {{method}}',
  pan: 'PAN',
  panLastDigits: 'Letzte Ziffern des PAN',
  panMasked: 'PAN verborgen',
  PANSeq: 'PANSeq',
  partial_payment: 'Teilzahlung',
  partial_payment_not_allowed_for: 'Teilzahlungen nicht zulässig für',
  partial_payment_paid_successfully: 'Teilzahlung erfolgreich',
  pay_later: 'Später bezahlen',
  pay_later_allows_only_refund_all: 'Bei "Später bezahlen" kann nur der Gesamtbetrag erstattet werden: für Teilerstattungen Gesamtzahlung abschließen',
  pay_later_refund: '"Später bezahlen" abbrechen',
  pay_on_account: 'Anzahlung',
  payconiq: 'Payconiq',
  paying_now: 'Sofortzahlung',
  payment_cancelled: 'Zahlung storniert',
  payment_error: 'Zahlungsfehler',
  payment_info: 'Zahlungsinformationen',
  payment_insert_card: 'Karte einstecken',
  payment_method_not_configured: '{{payment_method}} nicht korrekt auf diesem Gerät konfiguriert',
  payment_number: 'Zahlungsnummer',
  payment_processor_options: 'Zahlungsanbieteroptionen',
  payment_provider: 'Zahlungsanbieter ',
  payment_success: 'Zahlung erfolgreich',
  payment_terms_in_net_days: 'Zahlbar bis (Tage netto)',
  personal_information: 'Persönliche Angaben',
  pickup_rewards: 'Prämien bei Abholung',
  pin: 'PIN',
  please_insert_a_valid_amount: 'Bitte gültigen Betrag angeben',
  posEntryMode: 'posEntryMode',
  prefName: 'prefName',
  print: 'Drucken',
  print_also_product_details: 'Produktdetails mitdrucken?',
  print_error: 'Fehler beim Drucken',
  print_product_details: 'Produktdetails drucken',
  print_successful: 'Druck erfolgreich',
  printer_connect_desc: 'WiFi-Drucker müssen im selben Netzwerk wie dieses Gerät eingerichtet werden. \nBluetooth-Drucker müssen über die Systemeinstellungen gepaart werden.',
  printer_removed: 'Drucker entfernt',
  printer_test: 'Druckertest',
  printing_report: 'Bericht wird erstellt und gedruckt ...',
  proceed_with_next_payment: 'Zur nächsten Zahlung',
  processorName: 'processorName',
  product_is_disable_continue_anyway: 'Das gewählte Produkt ist nicht verfügbar (eventuell ausverkauft). Trotzdem zur Bestellung hinzufügen?',
  product_sync: 'Produkt-Synchronisierung',
  profile_average: 'Kaufdurchschnitt',
  protected_sections: 'Geschützte Bereiche',
  receipt_printer: 'Belegdrucker',
  redeem_reward: 'Prämie einlösen',
  referenceNumber: 'referenceNumber',
  refresh_customers: 'Aktualisieren Kundenliste',
  refresh_merchant: 'Kontodaten aktualisieren',
  refresh_transactions: 'Aktualisieren Transaktionen',
  refreshing_merchant: 'Kontodaten werden aktualisiert ...',
  refund_number: 'Rückerstattungsnummer',
  refunds_not_available_for_provider: 'Rückerstattungen nicht verfügbar für: {{payment_provider}}',
  register_terminal: 'Gerät registrieren',
  reissue: 'Neu ausstellen',
  remaining_payment: 'Restzahlung',
  remove_customer_from_cart: 'Aus Bestellung entfernen',
  remove_payment: 'Zahlung entfernen',
  remove_printer: 'Drucker entfernen',
  removing_products: 'Alte Produkte werden entfernt ...',
  reopen_shift: 'Kasse erneut öffnen',
  replace_customer: 'Kunden austauschen',
  report_by_shift: 'Tägliches Öffnen/Schließen zulassen',
  report_printer: 'Berichtsdrucker',
  requested_amount: 'Angeforderter Betrag',
  reward: 'Prämien',
  round_cash_transactions: 'Barzahlungen auf die nächste 0,05-Stelle runden',
  save_printer: 'Drucker speichern',
  scan: 'Scannen',
  scan_barcode_instruction_one: 'Barcode scannen, um Socket Bluetooth-Scanner zu verbinden',
  scan_barcode_instruction_two: 'Vor dem Verbinden mit einem Socket CHS-Scanner Bluetooth in Geräteeinstellungen aktivieren',
  scan_code_not_exist: 'Gescannter Barcode {{barcode}} unbekannt',
  scan_qr_code: 'QR-Code scannen',
  scanner_connected: 'Barcode-Scanner verbunden',
  scanner_disconnected: 'Keine Verbindung zu Barcode-Scanner',
  scanner_pair_completed: 'Pairing mit Barcode-Scanner abgeschlossen',
  search_for_customer: 'Kunde suchen',
  search_report: 'Berichte suchen',
  search_transaction: 'Transkation suchen',
  searching_terminals: 'Terminalsuche läuft ...',
  security_and_permissions: 'Sicherheit und Berechtigungen',
  select_one_payment_method: 'Mindestens eine Zahlungsmethode muss aktiviert sein',
  select_payment_method: 'Bitte Karten-AID aus Liste unten wählen',
  select_terminal: 'Terminal wählen',
  send_email: 'E-Mail senden',
  send_to_another_customer: 'An einen anderen Kunden senden',
  send_to_current_customer: 'An aktuellen Kunden senden',
  send_to_customer: 'An Kunden senden',
  sending_receipt: 'Beleg wird gesendet ...',
  server_error: 'Serverfehler',
  session: 'Sitzung',
  set_new_price: 'Neuer Preis',
  setting_saved: 'Einstellung gespeichert',
  settings_connected_terminals: 'Verbundenes Terminal',
  settings_printer_available_printers: 'Verfügbare Drucker',
  settlementDate: 'settlementDate',
  setup_admin_pin_in_settings: 'Im Konto-Bereich unter Einstellungen den Admin-PIN festlegen',
  shift_closed: 'Kasse wurde geschlossen',
  shift_opened: 'Kasse wurde geöffnet',
  shifts: 'Schicht',
  shopper_reference: 'ShopperReference',
  show_customer: 'Kundendaten auf Beleg anzeigen',
  show_employee: 'Mitarbeitername auf Beleg anzeigen',
  show_customer_payconiq: 'Eingecheckten Kunden auswählen oder Kunden-Barcode/-NFC-Token scannen',
  show_new_product_tile: '"Produkt erstellen"-Kachel anzeigen',
  show_password: 'Passwort anzeigen',
  sign_out_header: 'Sicher?',
  sign_out_warning: 'Das Abmelden aus der App setzt alle Einstellungen darin zurück, darunter Zahlungsanbieter und Drucker. Sollen die Einstellungen nicht gelöscht werden, bitte (mit dem ADMIN- oder einem Mitarbeiter-PIN) die "Sperren"-Funktion nutzen.',
  sign_up_to_print: 'Für Belegdruck einfach kostenlos registrieren: keine Kreditkartendaten erforderlich, jederzeit kündbar',
  sign_up_to_send_email: 'Für den Belegversand per E-Mail einfach kostenlos registrieren: keine Kreditkartendaten erforderlich, jederzeit kündbar',
  signing_out: 'Abmeldung läuft ...',
  signup_for_mailing_list: 'Kunden zur Mailing-List einladen',
  sorry_report_not_available: 'Bericht nicht verfügbar',
  start_using_pos: 'Jetzt die Vorteile von Countr im Verkauf sichern',
  state: 'Bundesland',
  status: 'Status',
  store_powered_by: 'Dieser Standort wird unterstützt von',
  street: 'Straße',
  suggested_amount: 'Empfohlener Betrag',
  suggested_tip: 'Empfohlenes Trinkgeld',
  support: 'Support',
  telephone: 'Telefon',
  terminal_port: 'Port für Terminal eingerichtet',
  terminal_selected: 'Terminal gespeichert',
  terminalId: 'TerminalID',
  test: 'Das ist ein Test',
  three_inch: '3 Inch',
  tid: 'GID',
  Time: 'Zeit',
  tip: 'Trinkgeld',
  tip_added: 'Trinkgeld hinzugefügt',
  to_pay: 'Zu bezahlen',
  total_amount: 'Gesamt',
  transaction_code: 'TransaktionsCode',
  transaction_date: 'TransaktionsDatum',
  transaction_finalized: 'Transaktion abgeschlossen',
  transaction_pending: 'Transaktion ausstehend',
  transaction_started: 'Transaktion gestartet',
  transaction_succeeded: 'Transaktion erfolgreich',
  transaction_time: 'Transaktionszeitpunkt',
  transaction_timeout: 'Transaktion dauert zu lange',
  transactionSeq: 'transactionSeq',
  transactionType: 'transactionType',
  try_it_now: 'Gleich ausprobieren',
  tsi: 'TSI',
  tvr: 'TVR',
  two_inch: '2 Inch',
  uncategorized: 'Keine Kategorie',
  valid_phone_number: 'Gültige Telefonnummer',
  validating_fields: 'Felder werden geprüft ...',
  validation_in_progress: 'Prüfung läuft',
  verification_code: 'Prüfcode',
  verification_method: 'Prüfmethode',
  waiting_for_customer: 'Auf Kunden warten',
  want_to_sync_products: 'Produktsynchronisierung starten?',
  yodopay: 'YodoPaY',
  you_must_select_a_store: 'Standort muss gewählt werden',
  add_name_device: 'Name für Tablet/Handy für einfache Identifikation eingeben',
  card_number: 'Kartennummer',
  change_language: 'Sprache ändern',
  choose_currency: 'Standardwährung wählen',
  clear_cart_after_finish: 'Name der Bestellung nach Zahlung zurücksetzen',
  confirm_new_language: 'Neue Sprache bestätigen?\nFür diese Änderung bitte App vollständig schließen und neustarten',
  customer_signature: 'Unterschrift des Kunden',
  day: 'Tag',
  device_name_mandatory: 'Gerätename muss vergeben werden',
  downloading_refunds: 'Rückerstattungen werden heruntergeladen ...',
  email_report: 'Bericht per E-Mail senden',
  email_successfully_sent_test: 'Test-E-Mail an Konto gesendet',
  enter_table_name: 'Tischname eingeben',
  error: 'Fehler',
  error_retrieve_refunds: 'Fehler beim Abrufen der Rückerstattungen',
  error_retrieve_transactions: 'Fehler beim Abrufen der Transaktionen',
  for_this_device_see_dashboard: 'Nur für dieses Gerät: für mehr Berichte siehe Dashboard',
  hide_new_sale: 'Neuer Verkauf\' Anzeige nach Zahlung ausblenden',
  last_transaction_result: 'Aktuelle Transaktionsergebnisse',
  merge_warning: 'Fügt die Inhalte der Bestellungen zusammen',
  month: 'Monat',
  post_code: 'PLZ',
  print_order: 'Drucker für Bestellungen anzeigen',
  products_added_removed_refresh: 'Produkte wurden hinzugefügt oder entfernt: Liste aktualisieren?',
  quarter: 'Quartal',
  refresh: 'Aktualisieren',
  refreshing_products: 'Produkte werden aktualisiert ...',
  removing_categories: 'Alte Kategorien werden entfernt …',
  removing_taxes: 'Alte Steuersätze werden entfernt ...',
  select_interface_mandatory: 'Interface muss ausgewählt werden',
  sms_successfully_sent_test: 'Test-SMS an Konto gesendet',
  splitOrderPrintByCategory: 'Bestellungen nach Kategorie getrennt drucken',
  stored_digitally: 'Digital gespeichert',
  stripe_id: 'stripeID',
  sync_support_info: 'Support-Infos synchronisieren',
  syncing_support_info: 'Support-Infos werden synchronisiert ...',
  table: 'Tisch',
  table_is_empty: 'Tisch ist frei',
  tables_or_carts: 'Restaurant-Funktionen aktivieren',
  uncertain_card_payment_success: 'Unsichere Kartenzahlung',
  unknown_category: 'Kategorie unbekannt',
  unsynced_transactions_report_incorrect: '{{transaction_number}} Transaktion(en) nicht synchronisiert: Berichte nicht am aktuellsten Stand',
  update_terminal_firmware: 'Terminal-Firmware aktualisieren',
  week: 'Woche',
  year: 'Jahr',
  email_queued: 'E-Mail in Bearbeitung',
  order_received: 'Neue / aktualisierte Bestellung erhalten',
  go_to_orders: 'Bestellungen anzeigen',
  open_carts: 'Bestellungen öffnen',
  auto_print_order: 'Nicht gedruckte Bestellungen bei Zahlung drucken',
  auto_print_order_cart: 'Nicht gedruckte Bestellungen bei Warenkorb-/Tischwechsel drucken',
  external: 'Sonstiges',
  no_printer_found_tcp: 'Drucker nicht gefunden\nHier klicken und Drucker manuell hinzufügen',
  downloading_carts: 'Bestellungen werden heruntergeladen ...',
  pay_later_hybrid: 'Später bezahlen',
  error_retrieve_carts: 'Fehler beim Abrufen der Bestellungen',
  no_ip_address: 'Keine Adresse',
  building_number: 'Hausnummer',
  confirm_manual_payment: 'Manuelle Zahlung bestätigen',
  shipping_address: 'Lieferadresse',
  birth_date: 'Geburtsdatum',
  for_this_store_see_dashboard: 'Nur für diesen Standort: für mehr Berichte siehe Dashboard',
  customers: 'Kunden',
  tickets_in: 'Tickets ein',
  tickets_out: 'Tickets aus',
  tickets_in_out: 'Tickets ein/aus',
  unsynced_transaction_cant_refund: 'Transaktion noch nicht mit Server synchronisiert: Rückerstattung nicht möglich',
  show_stock_level: 'Aktuellen Lagerstand (bei Verfügbarkeit) anzeigen',
  available: 'Auf Lager',
  stock_not_available: 'Lagerstand unbekannt',
  order_another_store: 'Bei anderem Standort bestellen',
  order_products: 'Bestellungen von Produkten an anderen Standorten zulassen',
  trial_expired: 'Testzeitraum abgelaufen, POS-Funktionen deaktiviert',
  left_menu: 'Navigationsleiste links',
  right_menu: 'Navigationsleiste rechts',
  direct_pay_if_one_method: 'Zahlung automatisch starten, wenn 1 Methode eingestellt',
  processing_vouchers: 'Treueprämien und Gutscheine werden verarbeitet ...',
  display_2_rows_of_categories: 'Kategorien in 2 Reihen anzeigen',
  unknown_barcodes_as_vouchers: 'Nicht erkannte Barcodes wie Gutscheine verarbeiten',
  validate_signature: 'Unterschrift des Kunden korrekt?',
  group_items_in_receipt_and_cart: 'Gleiche Artikel bei Bestellung und auf Beleg zusammenfassen',
  ask_customer_subscribe_email: 'Kunde nach E-Mail-Adresse fragen',
  hide_categories: 'Kategorien ausblenden',
  hide_all_products_category: 'Kategorie "Alle Produkte" ausblenden',
  provisional_receipt: '*** VORLÄUFIG ***',
  reprint: '*** NACHDRUCK ***',
  block_reprints: 'Nachdruck von Artikeln nicht zulassen',
  reports_at_store_level: 'Berichte für Standort statt Gerät, anzeigen',
  max_addon_qty_exceeded: 'Maximal {{maxQty}} Add-ons für diese Gruppe zulässig',
  min_addon_qty_unmet: 'Mindestens {{minQty}} Add-ons für diese Gruppe erforderlich',
  press_button_to_wake_terminal: 'Grünen Knopf drücken, um PIN-Terminal zu aktivieren',
  payments: 'Zahlungen',
  average_sale_duration_mins: 'Durchschnittliche Verkaufsdauer (min)',
  save_order_move_next: 'Bestellung speichern und weiter zum nächsten freien Tisch',
  max_orders_reached: 'Maximale Anzahl offener Bestellungen erreicht',
  show_open_orders: 'Offene Bestellungen in der Liste zuerst anzeigen',
  tender_created: 'Transaktion gestartet',
  invalid_request: 'Ungültige Anfrage',
  already_processed: 'Transaktion bereits verarbeitet',
  invalid_terminal: 'Ungültiges Zahlungsterminal',
  network_error: 'Netzwerkfehler',
  bad_credentials: 'Anmeldedaten ungültig',
  server_issue: 'Server nicht erreichbar',
  pin_entered: 'PIN eingegeben',
  initial: 'Initialisierung',
  card_inserted: 'Karte gesteckt',
  card_swiped: 'Karte durchgezogen',
  wait_app_selection: 'Warte auf die Auswahl der Anwendung',
  wait_amount_adjustent: 'Warte auf Betragsanpassung',
  ask_gratuity: 'Nach Trinkgeld fragen',
  gratuity_entered: 'Trinkgeld eingegeben',
  ask_dcc: 'Nach DCC fragen',
  dcc_accepted: 'DCC akzeptiert',
  dcc_rejected: 'DCC abgelehnt',
  processing_tender: 'Angebot wird verarbeitet',
  wait_pin: 'Warte auf PIN',
  provide_card_details: 'Kartendaten zur Verfügung stellen',
  card_details_provided: 'Kartendaten zur Verfügung gestellt',
  receipt_printed: 'Beleg gedruckt',
  referral: 'Verweisung',
  referral_checked: 'Überweisung geprüft',
  check_signature: 'Unterschrift prüfen',
  signature_checked: 'Unterschrift geprüft',
  additional_data_available: 'Zusätzliche Daten verfügbar',
  transaction_acknowledged: 'Transaktion akzeptiert',
  device_already_registered: 'Gerät bereits registriert',
  add_in_progress: 'Füge Gerät bereits hinzu',
  app_not_registered: 'App nicht registriert',
  unknown_error: 'Unbekannter Fehler',
  timeout: 'Zeitüberschreitung',
  verify_failed: 'Gerät konnte nicht verifiziert werden',
  identify_failed: 'Gerät konnte nicht identifiziert werden',
  registration_error: 'Registrierungsfehler',
  sync_issue: 'Gerät konnte nicht synchronisiert werden',
  show_select_customer: 'Kundenselektor anzeigen',
  store_name_mandatory: 'Name des Standorts muss eingeben werden',
  choose_store_dashboard_for_more: 'Standort wählen: Backoffice-Dashboard öffnen, um mehr Standorte hinzuzufügen',
  recovering_incomplete_payment: 'Unvollständige Zahlungen werden wieder hergestellt: Vorgang kann bis zu 30 Sekunden dauern',
  allow_negative_sale: 'Negativen Verkaufsbetrag zulassen',
  move_current_table_to: 'Aktuellen Tisch ({{table_name}}) übertragen an',
  store: 'Standort',
  print_specific_categories: 'Bestimmte Kategorien drucken',
  customer_display_attached: 'Kundenanzeige beigefügt',
  sale: 'Verkauf',
  reps_and_waste_button_in_cart: 'Button für "Ertragfreie Transaktionen" bei Bestellung anzeigen',
  small_product_tiles: 'Produkte kleiner anzeigen',
  large_product_tiles: 'Produkte größer anzeigen',
  show_product_price_on_tile: 'Produktpreis anzeigen',
  printer_roles: 'Rollen für Drucker',
  paper_size: 'Papierformat',
  customer_display: 'Kundenanzeige',
  enable_realtime_updates: 'Echtzeitaktualisierungen vom Server zulassen',
  show_open_orders_first: 'Offene Bestellungen in der Liste zuerst anzeigen',
  blank_lines_order_ticket: 'Leerzeilen am Anfang des Bestellscheins',
  blank_lines_bottom_order_ticket: 'Leerzeilen am Ende des Bestellscheins',
  scan_card: 'Karte scannen',
  scan_nfc_or_barcode: 'NFC-Karte oder -Code scannen',
  show_product_keypad: 'Tastatur bei Bestellung anzeigen',
  extra_payment_methods: 'Zusätzliche Zahlungsmethoden',
  failed_recovery: 'Zahlung konnte nicht zurückgefordert werden',
  blank_line_between_order_items: 'Schwarze Linie zwischen Artikeln am Bestellstein',
  look_up_customers_using_barcodes: 'Kunde via Scanner zu einer Bestellung hinzufügen',
  error_loading_configuration: 'Fehler beim Laden der Konfiguration',
  error_verifying_payment_terminal: 'Fehler beim Überprüfen des PIN-Terminals',
  error_identifying_payment_terminal: 'Fehler beim Identifizieren des PIN-Geräts',
  error_saving_configuration: 'Fehler beim Speichern der Konfiguration',
  conflicting_server_modes: 'Widersprüchliche Modi auf PIN-Terminal und Server (TEST vs. PRODUKTION)',
  nothing_to_print: 'Keine zu druckende Artikel',
  large_item_name: 'Größe der gedruckten Artikel anpassen',
  print_ean_sku: 'EAN und SKU auf Beleg drucken',
  select_category_on_startup: 'Kategorie bei Start einmalig wählen',
  split_tickets_item: 'Bestellungen nach Artikel getrennt drucken',
  split_tickets_amount: 'Bestellungen nach Menge getrennt drucken',
  split_print: 'Getrennt drucken',
  refreshing_customers: 'Kunden werden aktualisiert ...',
  downloading_customers: 'Kunden werden heruntergeladen ...',
  error_retrieve_customers: 'Fehler beim Abrufen von Kunden.\nInternetverbindung prüfen und erneut versuchen',
  server_error_please_retry: 'Serverfehler: bitte erneut versuchen',
  vibrate_on_click: 'Vibrationsrückmeldung bei Artikelauswahl',
  keep_screen_on: 'Bildschirmschoner deaktivieren',
  refund_same_method: 'Rückerstattungen ausschließlich über ursprüngliche Zahlungsmethode',
  cannot_refund_more_than_paid: 'Via {{payment_method}} können maximal {{max_amount}} erstattet werden',
  tablet_date_incorrect: 'Gerätedatum falsch: mögliches Sicherheitsrisiko',
  extra_non_revenue_info: 'Zusätzliche Informationen für ertragfreie Artikel anfordern',
  enter_extra_info: 'Zusätzliche Artikelinformationen eingeben',
  giftcard_or_reference_no: 'Gutschein- oder Referenznummer',
  inserted_code_not_valid: 'Code falsch',
  validate_user: 'User-Prüfung ({{User-Adresse}})',
  enter_validation_code: 'Verifizierungscode aus SMS oder E-Mail eingeben',
  tax_rate: 'Steuersatz',
  pretax: 'Netto',
  deposit: 'Pfand',
  can_not_refund_with_different_provider: 'Transaktionen via {{Txn_payment_method}} können nicht via {{selected_payment_method}} erstattet werden',
  external_scanner_connected: 'Externer Scanner verbunden',
  cancelling: 'Abbruch',
  sumup: 'SumUp',
  ccv: 'CCV',
  payplaza: 'PayPlaza',
  worldline: 'Worldline',
  adyen: 'Adyen',
  rabosmartpin: 'Rabo SmartPin',
  rabosmartpinttp: 'Rabo SmartPIN TapToPay',
  exchange: 'Austausch',
  ask_exchange: 'Produktaustausch zulassen',
  exchange_error: 'Austauschfehler',
  exchange_items: 'Artikel austauschen',
  exchangeable: 'Austauschbar',
  to_be_exchanged: 'Auszutauschen',
  exchange_all: 'Alle austauschen',
  negative_sale_amount: 'Der zu zahlende Betrag ist kleiner als 0',
  download_rabosmartpin_app: 'Rabo SmartPin App herunterladen',
  transaction_disabled: 'Zahlungen von Dritten bei diesem Vertrag nicht zulässig',
  transaction_in_progress: 'Andere Zahlung bereits in Bearbeitung',
  refund_offline: 'Rückerstattungen sind im eingeschränkten Modus nicht möglich',
  refund_not_allowed: 'Angemeldeter Nutzer darf keine Rückerstattungen durchführen',
  cash_forbidden: 'Barzahlungen bei diesem Vertrag nicht zulässig',
  notify_new_online_order: 'Benachrichtigung anzeigen, wenn neue Online-Bestellung eingeht',
  print_new_online_order: 'Neue Online-Bestellungen nach Eingang automatisch drucken',
  delivery_information: 'Versandinformationen',
  voucher_already_processed: 'Gutschein bereits eingelöst',
  no_applicable_items_in_cart: 'Keine zutreffenden Artikel im Warenkorb gefunden',
  discount_applied: 'Rabatt angewendet',
  invalid_voucher: 'Gutschein ungültig',
  allow_voucher_scan: 'Gutscheine mit Barcode-Scanner prüfen',
  enable_drop_shipping: 'Drop-Shipping aktivieren',
  ask_no_covers_or_takeaway: 'Immer nach Tischkonfiguration fragen',
  table_configuration: 'Tischtyp',
  num_covers: 'Anzahl der Gäste',
  takeaway: 'Abholungen',
  guests: 'Gäste',
  show_floorplan_after_sale: 'Raumplan nach jedem Verkauf anzeigen',
  lock_after_x_minutes: 'Nach x Minuten Inaktivität sperren',
  show_deposit_items: 'Artikel mit Pfand anzeigen',
  pickup: 'Abholung',
  delivery: 'Lieferung',
  transaction_fee: 'Transaktionsgebühr',
  print_description: 'Artikelbeschreibung auf Beleg drucken',
  only_cash_for_negative_amounts: 'Für Beträge unter 0 nur Bar- oder manuelle Kartenzahlung verfügbar',
  phone_number_example: 'Telefon: z. B. 31611111111',
  whatsapp_not_available_on_this_device: 'WhatsApp ist auf diesem Gerät nicht installiert',
  receipt_not_yet_synchronised: 'Server-Synchronisierung läuft: bitte in Kürze erneut probieren',
  notify_online_orders_with_sound: 'Bei Online-Bestelleingang Ton abspielen',
  restrict_online_orders_by_floor: 'Online-Bestellungen nur für bestimmte Stockwerke zulassen',
  remove_product: 'Produkt entfernen',
  admin_access_needed: 'Funktion nur für Administratoren verfügbar',
  customer_or_employee_name: 'Name des Kunden oder Mitarbeiters',
  show_cart_on_left: 'Bestellung links anzeigen',
  groupOrderPrintByCategory: 'Druck von Bestellungen nach Kategorie gruppieren',
  handpoint_shared_secret: 'SharedSecret',
  fiscal_offline: 'POS und FDM muss bei der Verarbeitung von Transaktionen aus steuerrechtlichen Gründen online sein',
  unsynced_transaction_cant_reissue: 'Transaktion nicht mit Server synchronisiert: neu ausstellen nicht möglich',
  fiscal_info: 'Steuerrechtliche Daten',
  create_bundle: 'Set erstellen',
  select_items_to_bundle: 'Artikel für Set wählen',
  bundle_price: 'Set-Preis',
  create_bundle_items: 'Produkt-Set in der Bestellung erstellen',
  bundle: 'Set',
  giftcard_or_reference_no_mandatory: 'Gutschein- oder Referenznummer verpflichtend',
  show_camera: 'Gerätekamera (nach Möglichkeit) als Scanner verwenden',
  switch_camera: 'Kamera wechseln',
  camera: 'Kamera  ',
  print_qr_on_receipts: 'QR-Code auf Belegen drucken',
  no_floorplans_available: 'Keine Raumpläne verfügbar',
  receive_online_orders_for_multiple_stores: 'Online-Bestellungen für mehrere Standorte erhalten',
  inventory: 'Inventar',
  description: 'Beschreibung',
  brand: 'Marke',
  allergens: 'Allergene',
  hide_total_num_items_on_order: 'Gesamtanzahl der Artikel nicht am Bestellschein anzeigen',
  loss: 'Verlust',
  stolen: 'Diebstahl',
  own_use: 'Eigenbedarf',
  giveaway: 'Kundengeschenk',
  breakage: 'Bruchschaden',
  student_check: 'Schülerausweis prüfen',
  overview: 'Überblick',
  average: 'Durchschnittlich',
  busiest_hours: 'Stoßzeiten',
  rotation_time_in_secs: 'Bildwechsel in Sekunden',
  show: 'Anzeigen',
  uploaded: 'Hochgeladen',
  videos: 'Video',
  photos: 'Fotos',
  display_video_or_picture: 'Video, Fotos oder PDF-Dateien anzeigen',
  store_logo: 'Standort-Logo',
  add_selected_addon: 'Add-ons hinzufügen / entfernen',
  add_selected_to_stores: 'Standorte hinzufügen / entfernen',
  add_selected_categories: 'Kategorien hinzufügen / entfernen',
  select_categories: 'Kategorien wählen',
  add_selected_addon_group: 'Add-on-Gruppe hinzufügen / entfernen',
  company: 'Unternehmen',
  assortment: 'Sortiment',
  sales_history: 'Verkaufshistorie',
  employees: 'Mitarbeiter',
  extensions: 'Erweiterungen',
  desktop: 'Desktop',
  helpdesk: 'Helpdesk',
  specific: 'Spezifisch',
  no_data_for_this_period: 'Keine Daten in diesem Zeitraum',
  gross_revenue: 'Bruttoumsatz',
  avg_per_transaction: 'Durchschn. pro Transaktion',
  from: 'von',
  select_period: 'Zeitraum auswählen',
  date_start: 'Startdatum',
  date_end: 'Enddatum',
  select_store: 'Standort wählen',
  select_stores: 'Standorte wählen',
  all_stores: 'Alle Standorte',
  all_employees: 'Alle Mitarbeiter',
  all_devices: 'Alle Geräte',
  no_transactions_in_this_period: 'Keine Transaktionen in diesem Zeitraum',
  transactions_in_this_period: 'Transaktionen in diesem Zeitraum',
  report_graph: 'Berichtsgrafik',
  no_reports_found_for_this_period: 'Keine Berichte in diesem Zeitraum',
  top_sales: 'Beste Verkäufe',
  top_sellers: 'Bestseller',
  bottom_sellers: 'Low-Seller',
  no_top_sales_for_this_period: 'Keine Bestseller in diesem Zeitraum',
  best_selling_products_for_this_period: 'Bestseller in diesem Zeitraum',
  save_email: 'E-Mail speichern',
  new_password: 'Neues Kennwort',
  verify_password: 'Passwort bestätigen',
  save_password: 'Passwort speichern',
  contact: 'Kontakt',
  name_first: 'Vorname',
  name_last: 'Nachname',
  zip_code: 'Postleitzahl',
  region: 'Region',
  billing: 'Abrechnung',
  optional: 'Optional',
  business_name: 'Unternehmen',
  vat: 'MwSt',
  btw: 'MwSt',
  save_profile_info: 'Profildaten speichern',
  integrations: 'Integrationen',
  connect_to_your_existing: 'Verbinden mit bestehenden/m',
  invoices: 'Rechnungen',
  code: 'Code',
  isPaid: 'Bezahlt',
  invoice_paid: 'Rechnung bezahlt',
  actions: 'Aktionen',
  stores: 'Standorte',
  updated: 'Aktualisiert',
  updated_at: 'Aktualisiert am',
  create: 'Erstellen',
  info: 'Info',
  currencies: 'Währungen',
  save_edit_details: 'Daten speichern und bearbeiten',
  assigned_employees_of_this_stores: 'Diesem Standort zugewiesene Mitarbeiter:',
  not_assigned_employees: 'Nicht zugeordnete Mitarbeiter:',
  add_payment: 'Zahlung hinzufügen',
  payment_name: 'Zahlungsname',
  printer_name: 'Druckername',
  edit_storess_main_details: 'Hauptdaten des Standorts bearbeiten',
  edition: 'Edition',
  stores_address_details: 'Adressdaten des Standorts',
  stores_contact_details: 'Kontaktdaten des Standorts',
  assign_employees_for_your_stores: 'Mitarbeiter dem Standort zuweisen',
  create_custom_payment_methods_for_your_store: 'Standortspezifische Zahlungsmethoden erstellen',
  devices: 'Geräte',
  device: 'Gerät',
  hardware_info: 'Hardware-Info',
  uuid: 'UUID',
  manufacturer: 'Hersteller',
  os_name: 'OS-Name',
  os_version: 'OS-Version',
  pos_version: 'POS-Version',
  receipt_layout: 'Beleglayout',
  table_layout: 'Tischlayout',
  default_receipt: 'Standardbeleg',
  email_receipt: 'E-Mail-Beleg',
  change_image: 'Profilbild ändern',
  Tuesday: 'Dienstag',
  june: 'Juni',
  your_store_address: 'Standortadresse',
  edit: 'Bearbeiten',
  your_text_here: 'Euer Text',
  cheap: 'Billig',
  expensive: 'Teuer',
  client_receipt: 'Kundenbeleg',
  transaction_agreement: 'Transaktionsrichtlinien',
  your_footer_message_here: 'Euer Footer',
  image: 'Bild',
  price: 'Preis',
  edit_products_main_details: 'Hauptdaten des Produkts bearbeiten',
  details: 'Daten',
  visible: 'Sichtbar',
  in_pos: 'Im POS',
  cost: 'Kosten',
  cost_price: 'Selbstkosten',
  delivery_price: 'Lieferpreis',
  upload_progress: 'Fortschritt des Uploads ...',
  change_products_image: 'Produktbild ändern',
  variants: 'Varianten',
  variant: 'Variante',
  units: 'Einheiten',
  alert: 'Warnung',
  additional: 'Zusätzlich',
  ledger: 'Hauptbuch',
  subledger: 'Nebenbuch',
  addon: 'Add-on',
  addon_groups: 'Add-on-Gruppen',
  addon_group: 'Add-on-Gruppe',
  add_delete_and_edit_products_variants: 'Produktvarianten hinzufügen, löschen und bearbeiten.',
  assing_product_to_store: 'Produkt einem bestimmten Standort zuweisen (ohne bestimmten Standort ist das Produkt an allen Standorten verfügbar)',
  products_addtional_fields: 'Zusätzliche Produktfelder',
  manage_addons_group: 'Add-on-Gruppe',
  sold_by_weight: 'Verkauft nach Gewicht',
  dynamic_price: 'Dynamischer Preis',
  dynamic: 'Dynamischer Preis',
  min_age: 'Mindestalter',
  packaging: 'Verpackung',
  contents: 'Inhalt',
  form: 'Formular',
  products_packaging_and_suppliers_details: 'Verpackungs- und Lieferantendaten für das Produkt',
  stock: 'Bestand',
  management: 'Verwaltung',
  count: 'Anzahl',
  filter_name: 'Namen hier eingeben für Filter',
  find_products_category: 'Produkte nach Kategorie suchen',
  find_products_addons: 'Produkte nach Add-on suchen',
  in_stock: 'Auf Lager',
  order_below: 'Bestellung unten',
  order_quantity: 'Bestellmenge',
  advance: 'Vorauszahlung',
  view_history: 'Historie anzeigen',
  click_here_to_select_source: 'Hier klicken und Quelle wählen',
  record_missing_item: 'Fehlenden Artikel eintragen',
  lost: 'Verlust',
  current_stock: 'Aktueller Bestand',
  current_report: 'Aktueller Bericht',
  view_stock_history: 'Bestandshistorie anzeigen',
  color: 'Farbe',
  duplicate_item: 'Artikel duplizieren',
  want_duplicate_item: 'Diesen Artikel duplizieren?',
  delete_item: 'Artikel löschen',
  want_delete_item: 'Diesen Artikel löschen?',
  categories_visibility_changed: 'Sichtbarkeit geändert',
  change_visibility: 'Sichtbarkeit ändern',
  category: 'Kategorie',
  parent: 'Übergeordneter Artikel',
  select: 'Auswählen',
  rate: 'Bewerten',
  delete_selected_items: 'Ausgewählte Artikel löschen',
  aplly_diff_rates: 'Unterschiedliche Steuersätze für unterschiedliche Länder anwenden',
  countries_added: 'Länder hinzugefügt',
  groups: 'Gruppen',
  group: 'Gruppe',
  standard: 'Standard',
  default_text: 'Standardtext',
  business_rules: 'Geschäftsregeln',
  business_rule: 'Geschäftsregel',
  title: 'Titel',
  is_active: 'Aktiv',
  startdate: 'StartDatum',
  enddate: 'EndDatum',
  rules: 'Regeln',
  rule: 'Regel',
  edit_business_rules_main_details: 'Daten der Unternehmensregel bearbeiten',
  active: 'Aktiv',
  time_start: 'Startzeit',
  time_end: 'Endzeit',
  select_specific_days: 'Bestimmte Tage auswählen',
  conditions: 'Bedingungen',
  condition: 'Bedingung',
  change_business_rules_conditions: 'Bedingungen der Unternehmensregel ändern',
  change_business_rules_outcome: 'Ergebnis der Unternehmensregel ändern',
  minimum_amount: 'Mindestbetrag',
  minimum: 'Minimum',
  maximum: 'Maximum',
  maximum_amount: 'Höchstbetrag',
  ignore_for: 'Ignorieren für',
  outcome: 'Ergebnis',
  voucher_outcome: 'Gutscheinergebnis',
  new_price_rule: 'Neuer Preis für Unternehmensregel',
  min_pack_value: 'Min. Packungswert',
  max_pack_value: 'Max. Packungswert',
  enable: 'Regeln aktivieren',
  disable: 'Regeln deaktivieren',
  all: 'Alle',
  update_time: 'Start-/Endzeit aktualisieren',
  update_date: 'Start-/Enddatum aktualisieren',
  catalog_order: 'Katalogordnung',
  drag_and_drop_items_to_reorder: 'Um Anordnung zu ändern, Artikel ziehen und ablegen (Drag and Drop)',
  see_reports: 'Bericht im Dashboard anzeigen durch Auswahl von',
  generate_report: 'Bericht erstellen',
  or: 'Oder',
  send_report: 'Oder Rohdaten per E-Mail senden',
  send_report_button: 'Bericht senden',
  send_to: 'Senden an',
  recipient: 'Empfänger',
  available_reports: 'Für diesen Zeitraum verfügbare Berichte',
  select_currency_text: 'Währung und Standort wählen',
  tables: 'Tische',
  revenue: 'Umsatz',
  see_revenue: 'Bruttoumsatz, Kosten, Steuern und Gewinne anzeigen',
  net_revenue: 'Nettoumsatz',
  profit: 'Gewinn',
  see_transaction: 'Transaktionsmethoden, Menge und Gesamtwert anzeigen',
  see_payments: 'Zahlungsmethoden, Menge und Gesamtwert anzeigen',
  see_refunds: 'Rückerstattungsmethoden, Menge und Gesamtwert anzeigen',
  see_taxes: 'Steuern, Menge und Gesamtwert anzeigen',
  brands: 'Marken',
  channels: 'Kanäle',
  channel: 'Kanal',
  see_brands: 'Produkte nach Marke, Menge und Gesamtwert anzeigen',
  see_channels: 'Produkte nach Kanal, Menge und Gesamtwert anzeigen',
  see_categories: 'Produkte nach Kategorien, Menge und Gesamtwert anzeigen',
  click_details: 'Für mehr Infos hier klicken',
  report_select_other_period: 'Bitte Zeitraum ändern, um verfügbare Berichte anzuzeigen',
  created: 'Erstellt',
  discounts: 'Rabatte',
  add_discount: 'Rabatt hinzufügen',
  discount_percent: 'Rabatt in Prozent',
  register_opertations: 'Betrieb registrieren',
  operations: 'Betrieb  ',
  first: 'Vorname',
  last: 'Nachname',
  phone: 'Telefon',
  permission_to_contact: 'Kontakterlaubnis',
  identifiers_type: 'Art der Kundenkennung',
  identifiers_value: 'Kennungswert',
  shipping: 'Versand',
  diff_billing: 'Verrechnung (falls abweichend)',
  rights: 'Rechte',
  employee: 'Mitarbeiter',
  token: 'Token',
  uninstall_ext: 'Erweiterung deinstallieren',
  active_ext: 'Diese Erweiterung ist aktiv',
  subscribe: 'Abonnieren',
  remove_ext_msg: 'Erweiterung wirklich entfernen?',
  no_thanks: 'Bitte nicht',
  subs_to_ext: 'Erweiterungen abonnieren',
  period: 'Zeitraum',
  payment_details: 'Zahlungsinformationen',
  card_name: 'Name auf der Karte:',
  paymnet_method: 'Zahlungsmethode:',
  code_country: 'Ländercode:',
  diff_way_pay_msg: 'Oder andere Zahlungsmethode wählen',
  phone_support: 'Telefonischer Support',
  support_msg: 'Bei Fragen hilft unser Kundenservice gerne weiter:',
  email_support: 'E-Mail-Support',
  support_email: 'E-Mail-Adresse Support:',
  info_email: 'E-Mail-Adresse Info:',
  sales_email: 'E-Mail-Adresse Verkauf:',
  page: 'Seite',
  dark_theme: 'Dunkles Design',
  report_spec_time: 'Bericht mit bestimmter Zeit erstellen',
  vat_btw_no: 'UID',
  store_unable_delete: 'Dieser Standort kann nicht gelöscht werden, da Geräte verbunden wurden. Bitte Geräte anderem Standort zuweisen, um diesen Standort zu löschen.',
  important: 'Wichtig:',
  store_device_delete_msg: 'Vor Löschen des Geräts bitte Abmeldung durchführen',
  email_test_receipt: 'Testbeleg per E-Mail senden',
  merchant: 'Händler',
  per_item: 'pro Artikel',
  img_width_msg: 'Datei darf nicht breiter als 250px sein',
  err_img_msg: 'Fehler: Bild darf nicht breiter als 250px sein',
  rules_actions: 'Aktionen für Regeln',
  enable_all: 'Alle Unternehmensregeln aktivieren',
  want_enable_all: 'Alle Unternehmensregeln aktivieren?',
  disable_all: 'Alle Unternehmensregeln deaktivieren',
  want_disable_all: 'Alle Unternehmensregeln deaktivieren?',
  product_cat_error: 'Kategorie wählen',
  field_not_empty: 'Feld darf nicht leer sein',
  drop_files: 'Datei für Upload hier ablegen',
  save_variant_before_stock: 'Neue Variante vor dem Bearbeiten des Bestands speichern',
  no_employees: 'Mitarbeiter fehlen. Bitte Mitarbeiter erstellen:',
  here: 'hier',
  no_stock: 'Keine Produkte mit Bestandsüberwachung an diesem Standort',
  product_exists: 'Produkt gibt es nur hier:',
  add_units_stock: 'Einheiten werden dem Bestand hinzugefügt',
  remove_units_stock: 'Einheiten werden vom Bestand abgezogen',
  saving: 'Speichern ...',
  transfer_units: 'Anzahl der auf diesen Standort zu übertragenen Einheiten angeben',
  remove_units_store: 'Anzahl der von diesem Standort zu entfernenden Einheiten angeben',
  notification: 'Benachrichtigung',
  set_new_start_end_time: 'Neue Start-/Endzeit festlegen',
  set_new_start_end_date: 'Neues Start-/Enddatum festlegen',
  by_email: 'Per E-Mail',
  charts_graphs: 'Grafiken/Schaubilder',
  compare_reports: 'Berichte vergleichen',
  new_report: 'Neuer Bericht',
  basic: 'Basic',
  no_acount_to_send_report: 'Bericht mit diesem Konto nicht sichtbar:',
  upgrade_account_msg: 'Konto muss aktualisiert werden',
  gen_report_msg: 'Berichte können im Dashboard erstellt und eingesehen werden',
  disabled_report: 'Berichte deaktiviert, solange Rechnungen offen sind',
  please_check: 'Bitte prüfen:',
  email_wrong_format: 'E-Mail-Format ungültig',
  bar_chart: 'Balkendiagramm',
  method: 'Methode',
  total_taxed_amount: 'Besteuerter Gesamtbetrag',
  total_price_product: 'Gesamtpreis der Produkte',
  enter_value: 'Wert muss eingegeben werden',
  not_valid_mail: 'Keine gültige E-Mail-Adresse',
  enter_email: 'E-Mail-Adresse eingeben',
  unit_price: 'Preis pro Einheit',
  extras: 'Extras',
  monthly_billed: 'Verrechnung monatlich',
  removing_ext: 'Diese Erweiterung wird nach dem aktuellen Abrechnungszyklus entfernt',
  local_ip: 'Lokale IP',
  printer_ip_ad: 'IP-Adresse des Druckers',
  printer_categories: 'Druckerkategorien',
  printers: 'Drucker',
  payment_processor: 'Zahlungsanbieter',
  question: 'Noch Fragen?',
  check_here: 'Dann besucht unser',
  knowledge_center: 'Infocenter',
  support_articles_msg: 'In einer Reihe von Support-Artikeln begleiten wir euch bei den ersten Schritte und zeigen euch, wie man Produkte anlegt, Onlineshops verbindet und vieles mehr.',
  support_ques: 'Passende Antwort nicht gefunden?',
  in_touch: 'Dann meldet euch bei uns.',
  select_floorplan: 'Raumplan wählen',
  floors: 'Stockwerke',
  add_floorplan: 'Raumplan hinzufügen',
  delete_floorplan: 'Raumplan löschen',
  floorplan: 'Raumplan',
  add_tables: 'Tische hinzufügen',
  sign_up_msg: 'Tausende Händler nutzen bereits Countr. Jetzt seid ihr dran. Gleich kostenlos registrieren',
  user_required: 'Benutzername erforderlich',
  name_required: 'Name erforderlich',
  company_name_required: 'Unternehmensname erforderlich',
  phone_required: 'Telefonnummer erforderlich',
  pass_required: 'Passwort erforderlich',
  already_account: 'Schon ein Konto erstellt?',
  accept_sign_up: 'Mit der Registrierung stimme ich folgenden zu',
  terms_conditions: 'Allgemeinen Geschäftsbedingungen',
  policy: 'Datenschutzrichtlinien',
  licence_agree: 'Lizenzvereinbarung',
  your_name: 'Nutzername',
  company_web: 'Website des Unternehmens (optional)',
  forgot_pass: 'Passwort vergessen?',
  login: 'Anmelden',
  no_account_link: 'Noch kein Konto erstellt?',
  email_required: 'E-Mail erforderlich',
  send_link: 'Link senden',
  download_file: 'Datei herunterladen',
  account_number: 'Kontonummer',
  me_rules: 'Regel',
  by_volume: 'Bestseller (nach Volumen) in diesem Zeitraum',
  number_sold: 'Verkauft:',
  by_volume_bottom: 'Low-Sellers (nach Volumen) in diesem Zeitraum',
  create_compare_report: 'Vergleichsbericht erstellen',
  last_per: 'Zuletzt',
  no_per_data: 'Keine Berichte verfügbar',
  create_new_rep: 'Neuen Bericht erstellen',
  reset_pass_text: 'E-Mail-Adresse eingeben, um Passwort zurückzusetzen',
  enter_bus_info: 'Unternehmensdaten eingeben',
  organization: 'Name der Organisation',
  mandatory_fields: 'Alle Felder sind Pflichtfelder',
  invalid_eu: 'Ungültige UID für EU-Land',
  trial_expires: 'Test läuft bis',
  select_package: 'Paket auswählen',
  excluse_vat: 'exkl. MwSt.',
  instead: 'statt',
  save_payment: 'Zahlungsmethode für zukünftige Transaktionen speichern',
  procced_paymet: 'Weiter zur Zahlung',
  selected_package: 'Gewähltes Paket',
  selected_ext: 'Gewählte Erweiterungen',
  old_dashboard: 'Zurück zum alten Dashboard',
  subscription_msg: 'Wir gratulieren! Ihr geht mit eurem Standort bald live. Eure Transaktionen werden für immer gespeichert und alle Test-Markierungen verschwinden. Bevor ihr live geht, sichert euch bitte die Erweiterungen, die ihr auch in Zukunft nutzen möchtet.',
  transfer_from: 'Übertragung der Einheiten von',
  customer_purchase: 'Kundenkauf',
  total_spend: 'Gesamtausgabe',
  last_customers_transactions: 'Letzte Transaktionen des Kunden',
  email_recipient: 'E-Mail-Empfänger',
  show_revenue: 'Umsatz anzeigen',
  show_sales: 'Verkäufe anzeigen',
  visits: 'Besuche gesamt',
  select_action: 'Aktion wählen',
  show_receipt: 'Beleg anzeigen',
  set_values_for: 'Werte festlegen für',
  update: 'Aktualisieren',
  select_addons_group: 'Add-on-Gruppe wählen',
  select_addon: 'Add-on wählen',
  all_addon_groups_are_in_use: 'Liste ist leer, alle Add-on-Gruppen in Verwendung',
  store_hours: 'Betriebszeiten am Standort',
  opening_hours: 'Öffnungszeiten',
  no_opening_hours: 'Keine Öffnungszeiten',
  set_opening_hours: 'Öffnungszeiten festlegen',
  delivery_hours: 'Lieferzeiten',
  no_delivery_hours: 'Keine Lieferzeiten',
  set_delivery_hours: 'Lieferzeiten festlegen',
  ordering_hours: 'Bestellzeiten',
  no_ordering_hours: 'Keine Bestellzeiten',
  set_ordering_hours: 'Bestellzeiten festlegen',
  takeaway_hours: 'Abholzeiten',
  no_takeaway_hours: 'Keine Abholzeiten',
  set_takeaway_hours: 'Abholzeiten festlegen',
  delivery_zones: 'Liefergebiet',
  no_delivery_zones: 'Kein Liefergebiet',
  set_delivery_zones: 'Liefergebiet festlegen',
  Monday: 'Montag',
  Thursday: 'Donnerstag',
  Friday: 'Freitag',
  Saturday: 'Samstag',
  Sunday: 'Sonntag',
  set_opening_store: 'Öffnungszeiten für den Standort festlegen',
  set_delivery_store: 'Lieferzeiten für den Standort festlegen',
  set_ordering_store: 'Bestellzeiten für den Standort festlegen',
  set_takeaway_store: 'Abholzeiten für den Standort festlegen',
  set_zones_store: 'Liefergebiet für den Standort festlegen',
  no_data_added: 'Keine Daten hinzugefügt',
  add_hours: 'Zeiten hinzufügen',
  add_zones: 'Liefergebiet erweitern',
  order_sub: 'Unterkategorien sortieren',
  new_item: 'Neu',
  tips: 'Trinkgelder',
  see_busiest_hours: 'Stoßzeiten für gewählten Zeitraum anzeigen',
  see_employee_transactions: 'Transaktionen pro Mitarbeiter für gewählten Zeitraum anzeigen',
  see_employee_tips: 'Trinkgelder pro Mitarbeiter für gewählten Zeitraum anzeigen',
  see_employee_refunds: 'Rückerstattungen pro Mitarbeiter für gewählten Zeitraum anzeigen',
  see_customer_transactions: 'Transaktionen pro Kundentyp für gewählten Zeitraum anzeigen',
  see_customer_refunds: 'Rückerstattungen pro Kundentyp für gewählten Zeitraum anzeigen',
  new_customer: 'Neukunde',
  returning_customer: 'Wiederkehrender Kunde',
  no_customer: 'Keine Kundendaten',
  reps_waste: 'Ertragfreie Transaktionen',
  see_reps_waste: 'Ertragfreie Transaktionen nach Grund sortiert anzeigen',
  see_employee_reps_waste: 'Ertragfreie Transaktionen pro Mitarbeiter anzeigen',
  employee_report_graph: 'Mitarbeiterverkäufe nach Zeit anzeigen',
  not_sold: 'Nicht verkauft',
  non_selling_products_this_period: 'Nicht verkaufte Produkte in diesem Zeitraum (Produkte mit 0 Verkäufen)',
  compare_stores: 'Standorte vergleichen',
  average_amount_spent: 'Durchschnittlicher Warenkorbwert',
  average_amount_refunded: 'Durchschnittlicher Erstattungsbetrag',
  bundle_parts: 'Set-Teile',
  create_giftcard: 'Geschenkgutschein erstellen',
  giftcards: 'Geschenkgutscheine',
  select_graphs_type: 'Grafiktyp wählen',
  see_discount_percentage: 'Transaktionen mit Prozentabzug anzeigen',
  see_discount_by_product_percentage: 'Auf Produkte gewährte Rabatte anzeigen (Betragsrabatte werden in Prozent gerechnet)',
  discount_percentage: 'Rabatt (Prozent)',
  discount_numeric: 'Rabatt (Betrag)',
  see_discount_numeric: 'Transaktionen mit Betragsrabatten anzeigen',
  number_of_discounts: 'inklusive der Häufigkeit dieser Rabatte auf Produkt- oder Warenkorbebene',
  delivery_cost: 'Versandkosten',
  total_cash_after_tips_and_change: 'Barzahlung gesamt nach Trink- und Wechselgeld',
  assigned_stores: 'Zugewiesene Standorte',
  chat_iframe: 'Chat',
  mobile_phone: 'Handy',
  notifications: 'Benachrichtigungen',
  annualy_billed: 'jährlich abgerechnet',
  download_stock: 'Produkte mit Lagerbestand herunterladen',
  download_all_stock: 'Alle Produkte herunterladen',
  no_stock_products: 'Produkte ohne Lagerbestand festlegen',
  go_products_with_stock: 'Zu den Produkte mit Lagerbestand',
  save_all_changes: 'Alle Änderungen speichern',
  need_track_stock: 'Bestand muss überwacht werden, um ihn bearbeiten zu können',
  track_stock: 'Bestand überwachen',
  set_min_man: 'Minimum und Maximum für bestimmte Add-on-Gruppen festlegen',
  duplicate: 'Duplikat',
  clear_unsaved: 'Alle Änderungen löschen',
  select_allergens: 'Allergene wählen',
  translate_categories: 'Kategorien übersetzen',
  translate_groups: 'Gruppen übersetzen',
  add_cat_translation: 'Übersetzungen zur Kategorie hinzufügen',
  add_group_translation: 'Übersetzungen zur Gruppe hinzufügen',
  assigned_groups: 'Zugewiesene Gruppen',
  extension: 'Erweiterung',
  vouchers: 'Gutscheine',
  show_open_carts_from_store: 'Offene Bestellungen von Standort anzeigen',
  ean: 'Barcode (EAN)',
  sku: 'SKU',
  want_delete_all_items: 'Alle ausgewählten Artikel löschen?',
  want_to_change_visibility: 'Sichtbarkeit für alle ausgewählten Artikel ändern?',
  import_export: 'Import/Export',
  methods: 'Zahlungsmethode',
  stored_payment_info: 'Daten zur Zahlungsmethode anzeigen',
  stored_payment: 'Gespeicherte Zahlungsmethoden',
  report_spec_zone: 'Andere Zeitzone wählen',
  no_stock_stores: 'Keine Standorte verfügbar: Standorte erstellen und Produkte zum Bestand hinzufügen',
  create_custom_payment: 'Eigene Zahlungsmethoden für Standort erstellen',
  min_chars: 'mindestens 3 Zeichen',
  enable_deposit: 'Pfand zulassen',
  enable_daily_report: 'Täglichen Bericht aktivieren',
  reorder_email: 'E-Mail-Nachbestellung',
  enable_non_revenue: 'Als "ertragfrei" kennzeichnen',
  total_visits: 'Besuche gesamt',
  how_it_works: 'So funktioniert\'s',
  info_text_catalog_order_one: '•  Produkte an die gewünschte Stelle ziehen (Mauszeiger auf gewünschtem Artikel platzieren, linke Maustaste gedrückt halten und ziehen).',
  info_text_catalog_order_two: '•  Um ein Produkt auf einer anderen Seite zu platzieren, Mauszeiger über dem Produkt platzieren, rechte Maustaste drücken und die Seite auswählen, auf der das Produkt erscheinen soll.',
  verify_dialog: 'Ein Bestätigungscode wurde per E-Mail gesendet',
  verify_proceed_dialog: 'Bitte Branche des Unternehmens wählen und anschließend Konto bestätigen',
  export_customer: 'Kundendaten exportieren',
  disabled_floor: 'Stockwerk deaktiviert',
  filter_by_category: 'Nach Kategorie filtern',
  current_timezone: 'Lokale Zeitzone:',
  important_note: 'Wichtig:',
  transaction_note: 'Wir verrechnen im Testzeitraum nichts, benötigen jedoch eure Zahlungsdaten, damit ihr rasch live gehen könnt.',
  translate_name: 'Übersetzung für die folgenden Sprachen festlegen',
  translate_tool: 'Name übersetzen',
  payment_method_note: 'Zahlungsmethode kann nur gelöscht, aber nicht hinzugefügt oder bearbeitet werden. Neue Zahlungsmethode bei nächster Bezahlung hinzufügen',
  add_new_payment: 'oder neue hinzufügen',
  no_payment_method: 'Keine Zahlungsmethode gespeichert',
  daily_report: 'Tägliche Berichtszeit (24-Stunden-Anzeige)',
  it_seems_store: 'Der folgende Standort',
  no_stock_items: 'scheint nicht genug Artikel auf Lager zu haben. Trotzdem fortfahren?',
  export_file_finished: 'Export beendet, Link zum Download:',
  import_file_finished: 'Import erfolgreich beendet. Produkte gesamt:',
  stock_import_file_finished: 'Bestandsimport erfolgreich beendet.',
  error_stock_finished: 'Ein Fehler ist aufgetreten: Dateiformat und -inhalt prüfen',
  import_file_init: 'Datei wird verarbeitet. Nach Verarbeitungsende erhaltet ihr eine Benachrichtigung.',
  export_file_init: 'Datei wird verarbeitet. Nach Verarbeitungsende erhaltet ihr eine Benachrichtigung.',
  create_voucher: 'Gutschein erstellen',
  total_uses: 'Verfügbarer Betrag',
  scope: 'Umfang',
  numeric_discount: 'Betragsrabatt',
  start_date: 'Startdatum',
  expiry_date: 'Ablaufdatum',
  products_categories: 'Produkte oder Kategorien',
  search_products: 'Produkte suchen',
  selected_products: 'Ausgewählte Produkte',
  search_categories: 'Kategorien durchsuchen',
  selected_categories: 'Ausgewählte Kategorien',
  selected_items: 'Ausgewählte Artikel',
  local_prices: 'Lokale Preise',
  translate: 'Übersetzen',
  value: 'Wert',
  code_type: 'Codetyp',
  non_revenue: 'Ertragfrei',
  giftcard_product_alert: 'Geschenkgutscheinprodukt sollte immer ertragfrei sein',
  multi_countries_tax_rules: 'Steuersätze für mehrere Länder',
  countries_rates: 'Nationale Steuersätze',
  edit_store_themes: 'Design des Standorts bearbeiten (nur für Countr Kiosk)',
  themes: 'Designs',
  font: 'Font',
  current_theme: 'Aktuelles Design',
  add_theme: 'Design hinzufügen',
  delete_theme: 'Design löschen',
  body_background: 'Hintergrundfarbe',
  body_text: 'Textfarbe',
  primary_text: 'Primärfarbe für Text',
  secondary_text: 'Sekundärfarbe für Text',
  header: 'Hintergrundfarbe für Header',
  header_text: 'Textfarbe für Header',
  toolbar: 'Hintergrundfarbe für Symbolleiste',
  toolbar_text: 'Textfarbe für Symbolleiste',
  button_primary_text: 'Primärfarbe für Button-Text',
  button_primary_background: 'Primärfarbe für Button-Hintergrund',
  button_secondary_text: 'Sekundärfarbe für Button-Text',
  button_secondary_background: 'Sekundärfarbe für Button-Hintergrund',
  button_neutro_text: 'Neutro-Farbe für Button-Text',
  button_neutro_background: 'Neutro-Farbe für Button-Hintergrund',
  isActive: 'Aktiv',
  Wednesday: 'Mittwoch',
  answered: 'beantwortet',
  common: {"welcome":"Hallo {{name}}, {{count}} {{messages}} sind neu!"},
  username: 'Nutzername',
  show_price: 'Preis anzeigen',
  companyid: 'Unternehmensname',
  invalid_merchantid: 'Kontaktperson ungültig',
  invalid_businessid: 'Unternehmensname ungültig',
  all_categories: 'Alle Kategorien',
  close_search_product: 'Suche schließen und Suchfilter entfernen',
  firstid: 'Vorname',
  lastid: 'Nachname',
  storeid: 'Standortname',
  enter_a_printerid: 'Druckername eingeben',
  enter_orderid: 'Bestellname eingeben',
  enter_tableid: 'Tischname eingeben',
  customerid: 'Kunde',
  insert_printerid: 'Druckername eingeben (mindestens 3 Zeichen)',
  not_paid: 'Nicht bezahlt',
  menu_itemid: 'Menüartikelname (max. 12 Zeichen)',
  employeeid: 'Mitarbeiter',
  deviceid: 'Gerät',
  addid_device: 'Gerätename',
  deviceid_mandatory: 'Gerätename erforderlich',
  no_floorplans: 'Keine Raumpläne verfügbar',
  table_layout_advice: 'Bestandsimport erfolgreich beendet',
  merge_tables: 'Tische zusammenlegen',
  custom_item_not_valid: 'Sonderartikel ungültig',
  no_connection_sync: 'Um alle Transaktionen zu synchronisieren, bitte vor Verlassen der Anwendungen Verbindung wiederherstellen.',
  new_device_name: 'Neuer Gerätename',
  change_device_name: 'Gerätename ändern',
  change_store: 'Standort ändern',
  alert_change_store: 'Um diese Änderung anzuwenden, bitte App vollständig schließen und neustarten',
  customer_total_spend: 'Gesamtausgabe des Kunden',
  skip_payment_success_info: 'Info zu erfolgreicher Zahlung nicht anzeigen',
  cart_always_open: 'Bestellung immer rechts am Bildschirm anzeigen',
  show_calculator: 'Rechner anzeigen',
  selected: 'Ausgewählt',
  categories_tiles_position: 'Position der Kategoriekacheln',
  kiosk_mode: 'Kiosk-Modus',
  kiosk_mode_disabled: 'Kiosk-Modus deaktiviert',
  change_table: 'Tischname ändern',
  change_cart: 'Bestellname ändern',
  change_cart_tip: 'Sollte mindeste 2 Buchstaben enthalten',
  a4_paper: 'Auf A4-Papier drucken',
  auto_print: 'Beleg nach erfolgreicher Zahlung automatisch drucken',
  select_report: 'Berichtsart wählen',
  report_not_found: 'Bericht nicht gefunden',
  new_cart: 'Neue Bestellung',
  new_table: 'Neuer Tisch',
  delete_cart: 'Bestellung löschen',
  delete_cart_confirmation: 'Bestellung wirklich löschen?',
  product_tile_size: 'Produktanzeige vergrößern',
  load_more_transactions: 'Mehr Transaktionen laden',
  select_variant: 'Variante wählen',
  remember_password: 'Passwort merken',
  go_dashboard: 'Zum Backoffice',
  enable_cart_item_remove: 'Entfernen von Artikeln aus einer Bestellung zulassen',
  remove_item: 'Artikel entfernen',
  show_current_cart: 'Aktuelle Bestellung anzeigen',
  show_carts: 'Bestellliste anzeigen',
  show_tables: 'Raumplan anzeigen',
  open_receipt: 'Beleg öffnen',
  kds: 'Küchenanzeige',
  archive: 'Archiv',
  send_archive: 'Archivieren',
  awaiting_payment: 'Warte auf Zahlung',
  all_items: 'Alle Artikel',
  printed: 'Neu',
  pending: 'Neu',
  preparing: 'Vorbereiten',
  ready: 'Bereit',
  completed: 'Beendet',
  send_new: 'Auf Neu zurücksetzen',
  send_progress: 'Als "In Bearbeitung" kennzeichnen',
  send_ready: 'Als "Bereit" kennzeichnen',
  send_completed: 'Als "Beendet" kennzeichnen',
  active_orders: 'Aktive Bestellungen',
  all_orders: 'Alle Bestellungen',
  select_employee: 'Mitarbeiter wählen',
  select_status: 'Status wählen',
  send_all_to_status: 'Status aller Produkte ändern auf',
  no_open_orders: 'Keine Bestellungen ',
  click_refresh: 'Für Aktualisierung hier klicken',
  deliveredBy: 'Geliefert von',
  by: 'Geliefert von',
  deliveryTime: 'Lieferzeit',
  pickupTime: 'Abholzeit',
  customerPhone: 'Telefonnummer Kunde',
  customerName: 'Kundenname',
  orderNote: 'Bestellnotiz',
  serviceCharge: 'Servicegebühr',
  orderType: 'Lieferart',
  deliveryCost: 'Lieferkosten',
  discountTotal: 'Rabatt',
  channelOrderDisplayId: 'Bestellnummer',
  orderIsAlreadyPaid: 'Bezahlt',
  paymentMethod: 'Zahlungsmethode',
  deliveryFee: 'Liefergebühr',
  deliveryAddress: 'Adresse',
  deliveryPostcode: 'PLZ',
  deliveryCity: 'Stadt',
  extraAddressInfo: 'Zusatzinfo',
  postalCode: 'PLZ',
  streetNumber: 'Hausnummer',
  postponed: 'Aufgeschoben',
  warning_delay: 'Warnung: Bestellungen verspäten sich (in Minuten)',
  load_orders_past: 'Bestellungen der letzten Tage laden',
  hotkeys: 'HotKeys',
  show_categories_separator: 'Kategorietrennzeichen bei jedem Artikel der Bestellung anzeigen',
  auto_refresh: 'Liste der Bestellungen alle X Minuten automatisch aktualisieren',
  auto_complete_order: 'Bestellungen nach x Minuten automatisch abschließen',
  hide_completed_items: 'Fertiggestellte Artikel auf Bestellung ausblenden',
  remove_grid_gaps: 'Lücken zwischen Bestellungen im Raster entfernen',
  off: 'Aus ',
  languages: 'Um alle Transaktionen zu synchronisieren, bitte vor Verlassen der Anwendungen Verbindung wiederherstellen',
  hotkeys_obj: {"error":"Kann Keys nicht wiederholen!","select_order":"Bestellung von 1 bis 9 auswählen","change_status_info":"Status der ausgewählten Bestellungen ändern auf:","change_status":"Bestellstatus ändern"},
  last_update: 'Letzte Aktualisierung',
  customer_information: {"first_name":"Vorname","last_name":"Nachname","phone":"Telefonnummer"},
  in_progress: 'In Bearbeitung',
  deliveryBy: 'Geliefert von',
  init_all_services: 'Alle Services initialisieren ...',
  email: 'E-Mail-Adresse',
  signin: 'Anmelden',
  signin_error: 'Während der Anmeldung ist etwas schiefgelaufen',
  device_name: 'Gerätename',
  close_details: 'Details schließen',
  no_orders_available: 'Keine Bestellungen verfügbar',
  order_has_been_completed: 'Bestellung {{receipt_id}} abgeschlossen',
  no_products: 'Keine Produkte',
  print_device: 'Drucker',
  send_order: 'Bestellung absenden',
  add_products: 'Produkte hinzufügen',
  no_categories: 'Keine Kategorien gefunden',
  empty_table: 'Freier Tisch',
  order_not_sent: 'Bestellung nicht an Küche gesendet',
  product_list_view: 'Produktlistenansicht',
  no_description: 'Dieses Produkt hat keine Beschreibung. Über den Ändern-Button oder via Dashboard kann eine Beschreibung hinzugefügt werden.',
  change_description: 'Produktbeschreibung eingeben',
  main_cart_is_empty: 'Hauptbestellung ist leer',
  apply_to_all_carts: 'Auf alle Bestellungen anwenden',
  per_value_payment: ' (Nennwertzahlung)',
  select_a_seat: 'Sitzplatz wählen',
  sub_total: 'Zwischensumme',
  desktop_settings: 'Desktop',
  carts: 'Bestellungen',
  signing_in: 'Anmeldung läuft ...',
  total_taxes: 'Steuern gesamt',
  provide_refund_item: 'Artikel für Rückerstattung muss ausgewählt werden',
  manual_cheque_refund: 'Manuelle Scheck-Rückgabe',
  downloading_employees: 'Mitarbeiter werden heruntergeladen ...',
  show_product_image_list: 'Produktbild in Produktliste anzeigen',
  refresh_delta: 'Produkte/Kategorien aktualisieren',
  admin_pin_incorrect: 'Admin-PIN falsch! Bitte erneut versuchen.',
  customer_not_valid_vat: 'Kunden-UID ungültig oder leer',
  allow_partial_payment: 'Teilzahlung zulassen',
  allow_qrcode_scanner: 'Auffinden von Transaktionen via Barcode-Scanner zulassen',
  qrcode_not_found: 'QR-Code nicht gefunden',
  empty_pin: 'PIN muss mindestens ein Zeichen haben',
  employee_select_error: 'Admin-PIN falsch! Bitte erneut versuchen',
  employee_select_success: 'Mitarbeiter {{employee}} ausgewählt.',
  employee_lock: 'Nach einer Transaktion Mitarbeiter immer abmelden',
  no_employee_store: 'Diesem Standort ist kein Mitarbeiter zugewiesen',
  cash_in_out_failed: 'Bargeld ein/aus konnte nicht gespeichert werden',
  cash_in_out_success: 'Bargeld ein/aus erfolgreich erfasst',
  categories_alphabetic_order: 'Kategorien alphabetisch sortieren',
  products_alphabetic_order: 'Produkte alphabetisch sortieren',
  weight_not_valid: 'Füllgewicht ungültig',
  kiosk_mode_requirement: 'Kiosk-Modus nur mit Admin-PIN aktivierbar',
  canceled: 'Storniert',
  partial_payment_value: 'Nach Wert',
  partial_payment_item: 'Nach Artikel',
  continue_payment: 'Zahlung fortsetzen',
  unit: 'Einheit',
  cheque: 'Scheck',
  need_store_register_device: '{{employee}} ausgewählt',
  search_transaction_hint: 'Mindestens 3 Zeichen, dann EINGABE drücken',
  select_pay_method: 'Zahlungsmethode wählen',
  delete_all_empty_cart: 'Alle leeren Bestellungen löschen',
  delete_all_empty_table: 'Alle leeren Tische löschen',
  delete_all_empty_cart_confirmation: 'Alle leeren Bestellungen wirklich löschen?',
  all_empty_carts_deleted: '({{cartsNumber}} Bestellungen) gelöscht',
  reps: 'Ertragfrei',
  show_reps_waste: 'Ertragfrei-Button bei Bestellung anzeigen',
  giveaway_reason: 'Grund für Geschenk oder Schaden',
  countr_desktop_not_found: 'Countr Desktop nicht gefunden',
  clear_search: 'Suche löschen',
  recovery_transaction_complete: 'Transaktion ({{ref}}) abgeschlossen',
  activate_simple_tiles: 'Einfache Produktliste aktivieren',
  not_print_receipt_cash: 'Beleg für Barzahlungen und Geschenkgutscheine nicht automatisch drucken',
  allow_negative_payments: 'Negativen Verkaufsbetrag zulassen',
  negative_payments_warning: 'Vor dieser Aktion müssen negative Zahlungen in den Einstellungen (Transaktionsbereich) zugelassen werden',
  enable_method: 'Methode zulassen',
  error_addon_groups: 'Mit der Add-on-Gruppe dieses Produkts stimmt etwas nicht: bitte im Backoffice prüfen',
  billed_to: 'Verrechnet an',
  payment_due: 'Zahlung fällig',
  amount_due: 'Betrag fällig',
  total_price: 'Gesamtpreis',
  show_invoice: 'Rechnung anzeigen',
  hide_invoice: 'Rechnung ausblenden',
  show_ean_sku_receipt: 'EAN auf Beleg anzeigen',
  show_simple_receipt: 'Einfachen Beleg anzeigen',
  not_tracked: 'Nicht nachverfolgt',
  kitchen_print: 'Ausdruck für Küche',
  kitchen_delivery_print: 'Lieferausdruck für Küche',
  syncing_transactions: 'Transaktionen werden synchronisiert ...',
  syncing_refunds: 'Rückerstattungen werden synchronisiert ...',
  barcode_customer: 'Mit Barcode-Scanner nach Kunde suchen',
  customer_add_cart: 'Kunde wurde der Bestellung hinzugefügt',
  transaction_not_sync_yet: 'Transaktion noch nicht mit dem Server synchronisiert',
  intercom_error: 'Kann Intercom Plug-in nicht starten: Adblock-Erweiterungen prüfen oder via Dashboard nutzen',
  pay_before_open_error: 'Vor Transaktionen muss Schicht gestartet werden',
  cash_in_not_open_day: 'Vor Barzahlungen ein/aus muss Schicht gestartet werden',
  cash_in_greater_start: 'Ungültiger Betrag: ist mehr Bargeld als bei Schichtbeginn vorhanden, Bargeld ein durchführen',
  open_close_shift: 'Schicht starten & beenden',
  opening_shift: 'Schicht wird gestartet ...',
  closing_shift: 'Schicht wird beendet ...',
  not_valid_input: 'Betrag ungültig',
  previous_start_amount: 'Voraussichtlicher Betrag',
  reason_mandatory_helper: '* Bitte Grund für Differenz eingeben',
  printer_settings: 'Druckereinstellungen',
  printer_ip: 'Drucker-IP',
  test_printers: 'Drucker testen',
  printers_settings_saved: 'Druckeinstellungen gespeichert',
  error_shift_start_day: 'Starttag der Schicht nicht gefunden',
  no_connection_payment: 'Keine Internetverbindung: vor der Kartenzahlung Verbindung wiederherstellen',
  show_order_bill: 'Bestell-/Verrechnungsbutton in Header anzeigen',
  split_orders_items: 'Bestellungen nach Artikel getrennt drucken',
  split_orders_amount: 'Bestellungen nach Betrag getrennt drucken',
  partial_payment_print: 'Beleg für Teilzahlung drucken',
  minimum_age: 'Prüfung des Mindestalters',
  minimum_age_alert: 'Kunde: {{customer}}. Kunde muss über {{age}} Jahre alt sein. Bitte prüfen.',
  customer_required_title: 'Kunden eingeben',
  customer_required_text: 'Für diese Zahlungsmethode muss der Kunde eingegeben werden',
  show_pretax_receipt: 'Oberhalb des Steuerbetrags anzeigen',
  show_full_receipt_after_payment: 'Nach Zahlung vollständigen Beleg anzeigen',
  new_version_title: 'Neue Version verfügbar',
  new_version_text: 'Neuer Inhalt verfügbar, bitte aktualisieren',
  new_version_text_hint: 'Versionshinweise im linken Menü einsehbar',
  reload: 'Neu laden',
  release_note: 'Versionshinweis ',
  play_sound_success_scan: 'Bei erfolgreichem Barcode-Scan Ton abspielen',
  manual_sync_transactions: 'Transaktionen manuell synchronisieren',
  manual_sync_transactions_question: 'Sollen diese Transaktionen manuell synchronisiert werden?',
  improvement: 'VERBESSERUNG',
  fixed: 'KORRIGIERT',
  recover_carts: 'Bestellungen wiederherstellen',
  recover_carts_alert: 'Soll diese Bestellung tatsächlich auf dieses Gerät übertragen werden? Sie wird nach dem Transfer vom anderen Gerät entfernt.',
  recover_carts_complete: 'Bestellungen wiederhergestellt und über Bestellauswahl verfügbar',
  select_all_carts: 'Alle Bestellungen auswählen',
  payment_started: 'Zahlung gestartet ...',
  outstanding_inovices: 'Es gibt ausstehende Rechnungen: bitte über das Dashboard ausgleichen',
  quota_warning_title: 'Speicherkapazität fast vollständig erschöpft',
  quota_warning_text: '{{percent}} % der Kapazität verbraucht',
  quota_warning_text_hint: 'Speicherplatz am Gerät freimachen',
  welcome_to: 'Willkommen',
  kassa_open: 'Verfügbare Registrierkassen',
  kassa_closed: 'Geschlossene Registrierkassen',
  kassa_closed_hint: 'Zu anderer Registrierkasse gehen',
  enable_customer_screen: 'Kundenbildschirm aktivieren',
  cds_settings: 'Einstellungen für Kundenbildschirm',
  cds_port: 'Anschluss für Kundenbildschirm',
  web_pos: 'Web-POS',
  desktop_pos: 'Desktop-POS',
  pos: 'App-POS',
  no_internet_connection: 'Aktion kann nicht durchgeführt werden: keine Internetverbindung',
  storage: 'Speicher',
  storage_data: 'Speicher in Verwendung: {{quota}} %',
  extra_info_title: 'Zusätzliche Infos zum Artikel eingeben',
  extra_info_sub_title: 'Gutschein- oder Referenznummer',
  net: 'Netto',
  delivery_details: 'Lieferdetails',
  delivery_time: 'Lieferzeit',
  pickup_time: 'Abholzeit',
  order_type: 'Art der Bestellung',
  order_courier: 'Geliefert von',
  delivery_order_warning: 'Dies ist eine Lieferbestellung von {{source}}: neues Produkt kann nicht hinzugefügt werden',
  delivery_toast: 'Lieferbestellung erhalten: {{by}} - {{receipt_id}}',
  delivery_requested: 'Lieferanforderung: {{message}}',
  tables_or_carts_desc: 'Bestellnamen als Tische anzeigen (Bestellung 1 wird zu Tisch 1 usw.)',
  cart_always_open_desc: 'Bestellung (Artikel, Summe, Steuern ...) am Bildschirm rechts geöffnet lassen',
  show_product_image_desc: 'Produktbilder in der Artikelliste der Bestellung anzeigen',
  allow_cart_discounts_desc: 'Rabatte auf Gesamtbestellung zulassen',
  allow_product_discounts_desc: 'Rabatte auf einzelne Produkte zulassen',
  enable_cart_item_remove_desc: 'Button zum Entfernen eines Artikels in der Artikelliste der Bestellung anzeigen',
  show_calculator_desc: 'Ziffernblock für Mengeneingabe anzeigen',
  show_reps_waste_desc: 'Ertragfrei-Button im Header der Bestellung anzeigen',
  barcode_customer_desc: 'Kundenkennung kann mit Barcode-Scanner gescannt und einer Bestellung hinzugefügt werden',
  show_stock_level_desc: 'Bestandseinheiten des Produkts auf Produktkachel anzeigen',
  show_price_desc: 'Produktpreis auf Produktkachel anzeigen',
  barcode_scanner_desc: 'Produkt kann mit Barcode-Scanner gescannt und einer Bestellung hinzugefügt werden',
  play_sound_success_scan_desc: 'Ton abspielen, sobald Produkt erfolgreich gescannt und der Bestellung hinzugefügt wurde',
  show_product_image_list_desc: 'Produktbild in Produktliste anzeigen',
  activate_simple_tiles_desc: 'Produktkachel-Animationen und -Effekte entfernen (auf älteren Computern empfohlen)',
  product_tile_size_desc: 'Produktkachel doppelt so groß anzeigen',
  hide_categories_desc: 'Kategorieliste verstecken und Modal zwecks Auswahl hinzufügen',
  categories_alphabetic_order_desc: 'Kategorieliste in alphabetischer Reihenfolge anzeigen',
  products_alphabetic_order_desc: 'Produktliste in alphabetischer Reihenfolge anzeigen',
  skip_payment_success_info_desc: 'Dialog nach erfolgreicher Zahlung (Transaktionszusammenfassung) nicht anzeigen',
  allow_partial_payment_desc: 'Teilzahlungen zulassen (Kunden können den Gesamtbetrag mit unterschiedlichen Zahlungsmethoden begleichen)',
  allow_negative_payments_desc: 'Zahlungen mit negativem Wert zulassen',
  allow_qrcode_scanner_desc: 'QR-Code-Leser im Header der Bestellung anzeigen',
  show_pretax_receipt_desc: 'Summe vor Steuern am Beleg anzeigen',
  show_full_receipt_after_payment_desc: 'Vollständigen Beleg nach erfolgreicher Zahlung in der Transaktionszusammenfassung anzeigen',
  refund_same_method_desc: 'Rückerstattungen können nur über dieselbe Zahlungsmethode wie bei der ursprünglichen Transaktion ausbezahlt werden',
  enable_customer_screen_desc: 'Kundenbildschirmdaten zulassen (sofern Kundenbildschirme verfügbar sind)',
  kiosk_mode_desc: 'Kiosk-Modus aktivieren',
  admin_pin_desc: 'Admin-PIN aktivieren (zum Sperren und Entsperren des Kiosk-Modus)',
  employee_pin_desc: 'Mitarbeiterauswahl aktivieren',
  employee_lock_desc: 'Bei Verwendung von Mitarbeiter-PINs POS nach jeder Transaktion sperren, damit PINs immer neu eingeben werden müssen',
  open_close_shift_desc: 'Schicht starten/beenden aktivieren',
  reports_at_store_level_desc: 'Berichte auf Standortebene anzeigen (inklusive aller Transaktionen auf allen Geräten)',
  language_desc: 'POS-Sprache ändern',
  kitchen_print_desc: 'Bestellung nach erfolgreicher Transaktion drucken',
  kitchen_delivery_print_desc: 'Bestellung nach Erhalt einer Transaktionsbestätigung via Übertragungssystem drucken',
  show_order_bill_desc: 'Bestellung und Rechnung drucken im Header der Bestellung anzeigen',
  split_orders_items_desc: 'Bestellung nach Artikel getrennt drucken',
  split_orders_amount_desc: 'Bestellung nach Betrag getrennt drucken',
  partial_payment_print_desc: 'Teilbeleg nach jeder Teilzahlung drucken',
  show_ean_sku_receipt_desc: 'EAN/SKU auf Beleg anzeigen',
  a4_paper_desc: 'A4-Druck aktivieren',
  show_carts_list_alphabetic: 'Bestellliste alphabetisch sortieren',
  show_carts_list_alphabetic_desc: 'Bestellliste alphabetisch sortieren',
  show_cart_name_transaction: 'Bestellname auf Beleg anzeigen',
  show_cart_name_transaction_desc: 'Bestellname auf dem Beleg anzeigen (Transaktionsliste oder Transaktion vollständig)',
  open_kds: 'KDS',
  open_chat: 'Countr Chat',
  partial_payment_bigger_zero: 'Teilzahlungsbetrag muss größer als 0 sein',
  allow_product_exchanges: 'Produktaustausch zulassen',
  allow_product_exchanges_desc: 'Produkte, die der Kunde austauschen möchte, als negativen Betrag der Bestellung hinzufügen',
  exchangeable_items: 'Austauschbare Artikel',
  to_be_exchangeable: 'Auszutauschen',
  exchange_source: 'Quelle Austausch',
  inventory_not_found: 'Produktbestand nicht gefunden',
  drop_ship: 'Drop-Shipping',
  enable_drop_ship: 'Drop-Shipping zulassen',
  enable_drop_ship_desc: 'Drop-Shipping-Funktion zulassen',
  drop_ship_warning: 'Drop-Shipping: kein Kunde ausgewählt',
  cart_as_tables_not_available: '"Bestellung nach Tisch" funktioniert nur bei deaktivierten Raumplänen',
  enable_deposit_button: 'Pfand-Button anzeigen',
  enable_deposit_button_desc: 'Pfand-Button in Subheader der Bestellung anzeigen',
  allow_voucher_scan_desc: 'Rabattgutschein mit Barcode-Scanner suchen und anwenden',
  remove_customer: 'Kunde entfernen',
  remove_customer_question: 'Kunde wirklich aus der Bestellung entfernen?',
  search_product_barcode_server: 'Produkte am Server suchen',
  search_product_barcode_server_desc: 'Produkte am Server suchen, wenn lokal nicht vorhanden',
  show_floorplan_after_sale_desc: 'Nach jedem Verkauf Raumplan statt Produktliste anzeigen ',
  ccv_settings: 'CCV-Einstellungen',
  ask_no_covers_or_takeaway_desc: 'Immer nach Tischkonfiguration fragen',
  send_order_kds: 'Bestellung wird an KDS gesendet',
  mollie: 'Mollie',
  paynl: 'PayNL',
  transactionFee: 'Transaktionsgebühr',
  pay_later_note_partial: '* Pro Transaktion maximal 1 "Später bezahlen"-Zahlung',
  no_print_delivery: 'Keine Lieferdaten für diese Bestellung',
  load_linked_cart: 'Zum Laden verknüpfter Informationen auf Tisch klicken',
  show_item_status: 'Artikelstatus bei Bestelleingang anzeigen',
  show_item_status_desc: 'Links von der Bestelleingabe Rahmen mit aktuellem Status des Artikels anzeigen',
  access_denied: 'Zugang verweigert! Bitte mit Masterkonto anmelden',
  current_floor: 'Aktuelles Stockwerk',
  total_to_pay: 'Zu bezahlen: {{currency}} {{amount}}',
  total_amount_paid: 'Bezahlt: {{currency}} {{amount}}',
  total_remaining: 'Rest: {{currency}} {{amount}}',
  redeem: 'Einlösen',
  current_balance: 'Aktueller Saldo',
  card_activity: 'Kartenaktivität',
  scan_fail: 'Suchfehler bei gescanntem Code',
  disallow_offline: 'POS muss bei Verwendung online sein',
  disallow_offline_fiscal: 'POS muss aus steuerrechtlichen Gründen bei Verwendung online sein',
  fiscal_print_error: 'Beleg kann nur mit Rechtshinweis gedruckt werden',
  enable_giftcard_scan: 'Scannen von Geschenkgutschein zulassen',
  enable_giftcard_scan_desc: 'Aktiviert die Funktion zum Scannen von Geschenkgutscheinen und Reduktion des Saldos',
  print_qr: 'QR-Code auf Beleg drucken',
  print_qr_desc: 'QR-Code am Ende des Belegs drucken',
  theme: 'Theme',
  theme_desc: 'Standardtheme nach eigenen Vorlieben festlegen (Themeliste nach Standort)',
  invalid_addon_groups: 'Mit den Add-on-Gruppen des Produkts stimmt etwas nicht: vor der Verwendung am Dashboard prüfen',
  products_not_found: 'Produkte nicht gefunden',
  show_suggestion_amounts: 'Buttons für vorgeschlagene Beträge anzeigen',
  show_suggestion_amounts_desc: 'Buttons für vorgeschlagene Beträge in Zahlungs-Modal anzeigen',
  save_table: 'Speichern',
  save_cart: 'Speichern',
  enable_groups: 'Bestellartikel gruppieren',
  highlight_items: 'Artikel hervorheben',
  set_ready_print: 'Als Bereit und Drucken kennzeichnen',
  start_preparing: 'Vorbereitung beginnen',
  group_qty: 'Mengen gruppieren',
  show_more: 'Mehr Information anzeigen',
  notes_per_qty: 'Notizen pro Menge',
  print_online_order_receipts: 'Für bezahlte Online-Bestellungen automatisch Belege drucken',
  select_items: 'Artikel wählen',
  partial_payment_not_allowed_by_value: 'Teilzahlungen sind nicht zulässig',
  product_media: 'Medien',
  show_product_media: 'Produktmedien anzeigen',
  change_seat: 'Sitzplatz ändern',
  add_seat: 'Sitzplatz hinzufügen',
  continue_partial_payment: 'Weitere Teilzahlung durchführen?',
  seat: 'Sitzplatz',
  partial_payment_seat: 'Pro Sitzplatz',
  processing: 'Verarbeitung läuft ...',
  groupOrderPrintBySeat: 'Bestelldruck nach Sitzplatz gruppieren',
  fiscal_error: 'Steuermodulfehler',
  switch_employee: 'Mitarbeiter wechseln',
  no_order_removed: 'Nicht bestellte Produkte entfernt',
  connected_device: 'Verbundenes Gerät',
  deleted: 'Gelöscht',
  ready_pickup: 'Bereit zur Abholung',
  employee_pin_changed: 'Mitarbeiter-PIN erfolgreich geändert',
  order_kds_no_printer_msg: 'Bestellung an KDS gesendet, jedoch nicht an den Drucker: Drucker einstellen, um Bestellung zu senden',
  order_kds_printer_msg: 'Bestellung an KDS und Drucker gesendet',
  add_table: 'Zum Tisch hinzugefügt',
  product_add_cart: 'Produkt zur Bestellung hinzugefügt',
  product_ordered: 'Produkt bestellt',
  product_not_ordered: 'Produkt nicht bestellt',
  save_guests: 'Gäste speichern',
  guests_num: 'Anzahl der Gäste',
  add_guest_num: 'Anzahl der Gäste hinzufügen',
  no_stores_created: 'Keine Standorte erstellt',
  device_print_msg: 'Ausgewählte Gerät steuert Belegdruck',
  prep_time: 'Vorbereitungszeit',
  no_tables_store: 'Keine Tische für diesen Standort',
  go_back: 'Zurück!',
  remove_not_order_msg: 'Nicht bestellte Artikel wirklich löschen?',
  order_not_sent_back: 'Bestellung nicht an KDS gesendet: Zurückgehen fortsetzen?',
  no_available_addons_for_products: 'Keine verfügbaren Add-ons für das ausgewählte Produkt',
  no_logged_employee: 'Keine Mitarbeiter angemeldet',
  empty_tables: 'Freie Tische',
  close_search: 'Suchleiste schließen',
  no_empty_tables: 'Keine freien Tische ',
  my_tables: 'Meine Tische',
  current_device: 'Aktuelles Gerät',
  employee_info: 'Mitarbeiterinformation',
  show_cart: 'Bestellung anzeigen',
  show_seat_separator: 'Sitzplatztrennzeichen anzeigen',
  set_print_device: 'Drucker einrichten',
  table_search: 'Tische suchen',
  order_by_seat: 'Bestellung pro Sitzplatz',
  table_list_view: 'Tische als Liste anzeigen',
  customer_groups: 'Kundengruppen',
  sales_insights: 'Verkaufsdaten',
  product_insights: 'Produktdaten',
  store_insights: 'Standortdaten',
  allow_ticket_scan: 'Zum Öffnen der Bestellung Tickets scannen',
  no_pin_employee: 'Mitarbeiter ohne PIN',
  allow_ticket_scan_desc: 'Für die Suche nach Tickets Barcode-Scanner nutzen',
  admin_only_custom_item: 'Sonderartikel nur für Admin freischalten',
  admin_only_custom_item_desc: 'Nur Nutzer mit Admin-Rechten können Sonderartikel erstellen',
  mandatory_employee_pin: 'Aktivierung des Mitarbeiter-PINs verpflichtend',
  admin_only_refunds: 'Nur Admins können Rückerstattungen durchführen',
  admin_only_refunds_desc: 'Nur Admins können Rückerstattungen durchführen und Mitarbeiter-PIN muss aktiviert sein',
  round_cash_transactions_desc: 'Barzahlung automatisch auf die nächste 0,05-Stelle runden',
  unlimited_usage: 'Uneingeschränkte Nutzung',
  infinite: 'Unbegrenzt',
  disable_custom_discounts: 'Sonderrabatte nicht zulassen  ',
  disable_custom_discounts_desc: 'Sonderrabatte nicht zulassen, ausschließlich vordefinierte',
  guest: 'Gast',
  select_method: 'Methode auswählen',
  manual_insert: 'Manuelle Eingabe',
  enter_code: 'Enter code ',
  existing_by_value_payment: '* Nach Wert bezahlen und nach Sitzplatz und/oder Artikel bezahlen kann gemischt verwendet werden',
  order_sent: 'Bestellung gesendet',
  select_multiple_seats: 'Mehrere Sitzplätze auswählen',
  change_seat_or_guest_num: 'Sitzplatz oder Gästenummer ändern',
  change_guest_num: 'Gästenummer ändern',
  giftcard_low_value: '* Gutscheinsaldo ist geringer als der Wert des/der ausgewählten Artikel/s. Bezahlung nach Wert probieren',
  order_by_seat_desc: 'Bestellung nach Sitzplatz aktivieren',
  legacy_images: 'Bilder im veralteten Format drucken',
  received_amount: 'Betrag erhalten',
  order_products_action: 'Produkte bestellen',
  ordered_products: 'Produkte bestellt',
  select_table: 'Tisch wählen',
  ordered: 'Bestellt',
  scan_or_type: 'Scannen oder tippen',
  type_code: 'Code eingeben',
  number_extension: 'Rufnummernerweiterung',
  move_processing_ticket_first: 'Zuerst Bearbeitungstickets verschieben',
  disable_adblocker: 'Bitte deaktivieren Sie Ihren AdBlocker und aktualisieren Sie die Seite',
  remove_partial_payment_by_item: 'Teilzahlung nach Artikel erkannt! Bitte entfernen Sie es, um mit dieser Aktion fortzufahren!',
  add_nutrients: 'Nährstoffe hinzufügen',
  giftcard_warning_msg: 'Die Standardgültigkeit einer Geschenkkarte beträgt 2 Jahre. Möchten Sie dies wirklich verringern?',
  edit_devicess_main_details: 'Bearbeiten Sie die Hauptdetails der Geräte.',
  create_custom_payment_methods_for_your_device: 'Erstellen Sie benutzerdefinierte Zahlungsmethoden für Ihr devi.',
  show_open_carts_from_device: 'Offene Bestellungen vom Gerät anzeigen',
  create_discounts_for_your_stores: 'Erstellen Sie Rabatte für Ihren Standort.',
  create_reps_waste_for_your_stores: 'Erstellen Sie Wiederholungen und Verschwendung für Ihren Standort.',
  show_cat_bar: 'Kategorieleiste anzeigen',
  bulk_update: 'Massenaktualisierung von Elementen',
  show_category_product_info: 'Kategorieinformationen im Produkt anzeigen',
  cart_will_empty: 'Der Warenkorb wird automatisch geleert',
  seconds: 'Sekunden',
  corrupted_addons: 'Das Produkt enthält beschädigte Add-Ons. Bitte schauen Sie im Backoffice nach.',
  add_more_items: 'Fügen Sie weitere Artikel hinzu',
  paying_with: 'Bezahlen mit {{method}}',
  screen_saver_delay: 'Bildschirmschonerverzögerung (Sekunden)',
  screen_saver: 'Bildschirmschoner',
  enable_screen_saver: 'Bildschirmschoner aktivieren',
  no_screen_saver: 'Bildschirmschoner kann nicht erkannt werden',
  screen_saver_info: 'Informationen zum Bildschirmschoner',
  type: 'Type',
  running_payment: 'In Bearbeitung',
  print_brand: 'Markennamen auf Beleg drucken',
  show_product_options_long_press: 'Lang drücken, um Produktoptionen anzuzeigen',
  asap: 'ASAP',
  order_source: 'Bestellquelle',
  ready_items: 'Fertige Artikel',
  disabled: 'Behindert',
  ready_orders: 'Fertige Bestellungen',
  admin_only: 'Nur für Administratoren verfügbar',
  print_tickets: 'Ticket-Barcodes auf Quittung drucken',
  print_tickets_desc: 'Drucken Sie Barcodes für alle auf der Quittung gefundenen Tickets auf der Quittung selbst',
  show_employee_on_product: 'Zeigen Sie, welcher Mitarbeiter jeden Artikel hinzugefügt hat',
  surcharge: 'Zuschlag',
  changes_list: 'Änderungsliste',
  action_type: 'Aktionstyp',
  previous_change: 'Vorherige Änderung',
  new_changes: 'Neue Änderungen',
  removed_fields: 'Entfernte Felder',
  updated_fields: 'Aktualisierte Felder',
  full_history: 'Vollständige Geschichte',
  changes_history: 'Änderungsgeschichte',
  product_history: 'Produktgeschichte',
  category_history: 'Geschichte der Kategorie',
  device_history: 'Gerätehistorie',
  enable_mandatory_customer_address: 'Obligatorische Kundenadresse',
  please_fill_all_fields: 'Bitte füllen Sie alle erforderlichen Felder aus: Hausnummer, Straße, Stadt, Bundesland/Kanton, Postleitzahl, Land, Vor- und Nachname, E-Mail-Adresse oder Telefonnummer',
  show_product_price_cat: 'Produktpreis und Kategorie anzeigen',
  open_cash_drawer_giftcard: 'Automatisches Öffnen der Schublade für Countr Geschenkkarten',
  generate_barcode: 'Barcode generieren',
  select_file: 'Datei aussuchen',
  drop_file: 'Datei ablegen',
  create_media: 'Erstellen Sie Medien für',
  upload_image: 'Bild hochladen',
  upload_file: 'Datei hochladen',
  add_custom_img: 'Fügen Sie Ihr benutzerdefiniertes Bild hinzu',
  select_media: 'Medientyp auswählen',
  external_url_iframe: 'Externer URL-Iframe',
  select_ticket_print: 'Wählen Sie den zu druckenden Typ aus',
  external_service_provider: 'Externer Dienstleister',
  barcode_input: 'Barcode-Eingabe',
  stock_load_msg: 'Überprüfung aller Produkte auf Lager. Das kann eine Weile dauern. Warten Sie mal!',
  no_available_stores: 'Keine Geschäfte verfügbar.',
  buy_combination_get_deal_new_price: 'Kombinieren Sie Artikel zum Vertragspreis',
  discount_specific_products_categories: 'Rabatt auf bestimmte Produkte oder Kategorien',
  discount_all_prodcucts: 'Rabatt auf alle Produkte',
  buy_x_items_get_cheapest_discount: 'Kaufen Sie x Artikel, erhalten Sie den günstigsten mit einem Rabatt',
  spend_certain_amount_get_discount: 'Geben Sie einen bestimmten Betrag aus, erhalten Sie einen Rabatt',
  customer_group_item_discount: 'Rabatt auf bestimmte Kundengruppen',
  item_surcharge: 'Zuschlag auf bestimmte Produkte, Kategorien oder Kundengruppen',
  cart_surcharge: 'Zuschlag auf die gesamte Bestellung',
  import: 'importieren',
  export: 'Export',
  simple: 'Einfach',
  advanced: 'Fortschrittlich',
  delete_import: 'Produktimport löschen',
  upload_csv: 'Laden Sie Ihre CSV-Datei hoch',
  open_file: 'Öffnen Sie die Datei mit',
  save_file: 'Speichern Sie die Datei als .csv',
  value_remaining: 'Restwert:',
  qr_codes_for_line_items: 'QR-Code statt Barcode für Werbebuchungen drucken',
  barcode_prefix: 'Barcode-Präfix',
  barcode_digits: 'Barcodelänge / Ziffern',
  external_identifier: 'Externe Kennung',
  cost_centre: 'Kostenstelle',
  shared_shift: 'Schicht teilen',
  shared_shift_desc: 'Bei einem Mitarbeiterwechsel muss die Kasse nicht erneut geöffnet werden',
  empty_cart_voucher_warning: 'Fügen Sie Produkte hinzu, um den Gutschein zu verwenden!',
  ask_for_help: 'Hilfe',
  help_is_coming: 'Hilfe kommt',
  you_paid: 'Du hast bezahlt.',
  end_msg: 'Danke und tschüss.',
  scan_products: 'Scannen Sie Ihr Produkt, um zu beginnen',
  welcome_valk: 'Willkommen bei Valk',
  solution_checkout: 'Lösungen zur Kasse!',
  basket_check_complete: 'Warenkorb Status prüfen',
  start_scanning: 'Bitte scannen Sie Ihr Produkt',
  close_auto: 'Automatisch schließen',
  open_close_employee: 'Öffnen & Schließen pro Mitarbeiter',
  print_table_name: 'Tischname auf Beleg drucken',
  print_note: 'Notiz auf Beleg drucken',
  reset_state: 'Anwendungsstatus zurücksetzen',
  sample_check_msg_top: 'Warten Sie mal. Es sollte eine Nachkontrolle stattfinden.',
  sample_check_msg_bottom: 'Ein Mitarbeiter wird so schnell wie möglich kommen.',
  disallow_close_register_open_tables: 'POS nicht schließen, wenn Bestellungen noch offen sind',
  opening_amount: 'Anfangsbetrag',
  cash_payments: 'Barzahlungen',
  receipt_type: 'Bitte wählen Sie die Art der Quittung',
  full_receipt: 'Vollständige Quittung',
  short_receipt: 'Kurze Quittung',
  start: 'START',
  go_to_cart: 'Zum Warenkorb',
  full_basket_check: 'Vollständiger Warenkorbcheck',
  missing_items_amount: 'Betrag der fehlenden Artikel',
  extra_items_scanned: 'Betrag für zusätzliche Artikel',
  finish_check: 'Prüfung abschließen',
  original_items: 'Originalartikel',
  checked_items: 'Geprüfte Artikel',
  no_receipt: 'Kein Beleg',
  refresh_categories: 'Kategorien aktualisieren',
  pay_img_text_up: 'Zahlen Sie mit Karte, <br /> folgen Sie den Anweisungen auf dem Karte-Gerät auf der linken Seite',
  pay_img_text_down: 'Nach der Zahlung folgt die Quittung',
  old: 'Alt',
  new: 'Neu',
  calculating: 'Berechnung',
  merging: 'Zusammenführung läuft',
  select_table_from_list: 'Wählen Sie eine Tabelle aus der Liste aus, um alle anderen ausgewählten Tabellen zusammenzuführen',
  cant_merge_same: 'Dieselbe Tabelle kann nicht zusammengeführt werden. Bitte wählen Sie eine andere Tabelle zum Zusammenführen aus',
  remove_from_table: 'vom Tisch entfernen',
  login_then_reboot: 'Bitte melden Sie sich zuerst an und starten Sie dann die Anwendung neu',
  eat_in: 'Iss hier',
  preparing_ods: 'In Vorbereitung',
  ready_ods: 'Bereit',
  locked_pos: 'POS ist gesperrt',
  fiscal_store_employee: 'Fiskalgeschäfte müssen einen Kassenmitarbeiter haben, um eine Kasse zu betreiben',
  invalid_employee: 'Ungültiger Mitarbeiter',
  update_employee_ssn: 'Bitte aktualisieren Sie die Sozialversicherungsnummer des Mitarbeiters',
  social_security_number: 'Sozialversicherungsnummer',
  fiscal_one_employee: 'Mindestens ein Mitarbeiter muss bei einem Fiskalgeschäft registriert sein',
  fiscal_employee_ssn: 'Mitarbeiter, die sich bei Fiskalgeschäften registrieren, müssen über eine gültige Sozialversicherungsnummer verfügen',
  employee_unable_delete: 'Der Mitarbeiter kann nicht gelöscht werden, da er mit einem Fiskalspeicher verknüpft ist. Bitte entfernen Sie den Mitarbeiter zuerst aus dem Geschäft.',
  vat_receipt: 'Dies ist ein Mehrwertsteuerbeleg',
  not_vat_receipt: 'Dies ist kein Mehrwertsteuerbeleg',
  deposit_error: 'Einzahlungsfehler',
  deposit_error_message: 'Sie können einem Produkt mit einem positiven Preis keine negative Anzahlung zuweisen. Nur Produkte mit einem Preis von 0 oder weniger können eine negative Anzahlung haben.',
  assign_printers_to_your_location: 'Weisen Sie Ihrem Standort Drucker zu',
  save_new_printer: 'Neuen Drucker speichern',
  update_printer: 'Drucker aktualisieren',
  location_no_saved_printers: 'Für diesen Standort sind keine gespeicherten Drucker vorhanden',
  sort_items_by_action: 'Elemente nach Aktion sortieren ( vorbereiten, neu, fertig )',
  fiscal_settings: 'Steuerliche Einstellungen',
  pos_serial_num: 'POS Seriennummer',
  print_x_report: 'X-Bericht drucken',
  shox_x_report: 'X-Bericht anzeigen',
  print_z_report: 'Z-Bericht drucken',
  show_z_report: 'Z-Bericht anzeigen',
  unfiscalised_transactions_report_incorrect: '{{transaction_number}} Transaktion(en) nicht fiskalisiert: Berichte nicht am aktuellsten Stand',
  zip_mandatory: 'Postleitzahl erforderlich',
  number_mandatory: 'Gebäudenummer erforderlich',
  multiple_options_found: 'Mehrere Optionen gefunden',
  print_card_info: 'Kartenzahlungsinformationen drucken',
  second_screen: 'Sekundäranzeige verfügbar',
  cashdrawer_partial_payment: 'Kassenschublade bei Teilzahlung öffnen',
  right: 'Rechts',
  left: 'Links',
  top: 'Spitze',
  bottom: 'Unterseite',
  refund_print: 'Rückerstattung',
  print_vat_name: 'Drucken Sie den MwSt.-Namen pro Artikel auf dem Kassenbon aus',
  proforma_ticket: 'Pro-Forma Ticket',
  receipt_already_printed: 'Quittung bereits gedruckt',
  allow_product_refunds: 'Produktrückerstattungen zulassen',
  allow_product_refunds_desc: 'Ermöglicht die Rückerstattung von Produkten',
  shift_per_store: 'Öffnen / Schließen Sie die Kasse pro Geschäft',
  shift_per_store_desc: 'Shift wird auf allen Shop-Geräten dauerhaft sein',
  create_custom_loyalty_methods_for_your_store: 'Fügen Sie Loyalitätsmethoden',
  cannot_combine_positive_and_negative: 'Auf demselben Beleg können nicht sowohl positive als auch negative Preise vorhanden sein',
  list_horizontal: 'Bestellungen horizontal anzeigen',
  opened_delivery_notes: 'Ausstehende Lieferscheine',
  payment_on_account: 'Zahlen Sie auf Rechnung',
  receipt_id: 'Empfangs-ID',
  invoice_number: 'Rechnungsnummer',
  customer_id: 'Kundennummer',
  delivery_notes: 'Lieferscheine',
  delivery_note_info: 'Lieferscheininformationen',
  download_invoice: 'Download Rechnung',
  invoice_info: 'Rechnungsinformationen',
  total_number: 'Gesamtzahl',
  create_invoice: 'Rechnung erstellen',
  rows_per_page: 'Zeilen pro Seite',
  plus_deposit_of: 'Preis zzgl. Anzahlung von',
  add_note_to_item: 'Notiz zum Artikel hinzufügen',
  round_electronic_transactions: 'Runden Sie elektronische Zahlungen auf das nächste 0,05',
  maximum_amount_single_transaction_excedeed: 'Die Transaktion überschreitet den Höchstbetrag',
  cannot_process_voucher_after_other_methods: 'Nicht erstattungsfähige Zahlungsmethoden können nicht nach anderen Zahlungen verarbeitet werden',
  all_giftcards_vouchers_processed: 'Wurden alle Geschenkkarten und/oder Gutscheine bearbeitet?',
  all_cash_processed: 'Wurden alle Barzahlungen bearbeitet?',
  disable_print_removed_items: 'Deaktivieren Sie das automatische Drucken entfernter Elemente',
  disable_print_removed_items_desc: 'Deaktivieren Sie das automatische Drucken entfernter Elemente, z. B. Artikel storniert',
  note_too_long: 'Notiz ist zu lang',
  one_ticket_per_cart: 'Es ist nur ein Ticket pro Wagen erlaubt',
  no_timeslots_available: 'Keine Zeitfenster verfügbar',
  download_paynl_app: 'Pay. App herunterladen',
  paynlsoftpos: 'Pay. SoftPOS',
  store_background: 'Hintergrundbild',
  disable_print_cache: 'Deaktivieren Sie die Zwischenspeicherung fehlgeschlagener Druckelemente',
  delivery_fees: 'Lieferkosten',
  keep_single_cart: 'Behalten Sie einen einzigen Warenkorb pro Gerät',
  cash_drawer_admin_only: 'Nur Administratoren können den Kassenkorb öffnen',
  cash_drawer_admin_only_desc: 'Angestellte ohne Administrationsrechte werden nicht in der Lage sein, die Kasse zu öffnen'
}

export default deLang
