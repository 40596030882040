import { memo, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { Text, translate } from 'react-internationalization'
import { cartUtils } from './../../utils/cartUtils'
import { CartUtils as Utils } from '@countr/utils'
import { Divider } from '@countr/ui'
import ProductUtils from '../../utils/ProductUtils'
import { AppInstances } from '../../utils/countrSdkInstance'
import { PaymentUtils } from '../../utils/PaymentUtils'

import './Receipt.css'

const mapStateToProps = state => {
  return {
    user: state.user,
    devices: state.devices,
    settings: state.settings,
    transactions: state.transactions
  }
}

const TRANSACTION = 'Transaction'
const REFUND = 'Refund'

const Receipt = memo(props => {
  const [isReceiptAvailable, setIsReceiptAvailable] = useState(false)
  const [receipt, setReceipt] = useState({})
  const [taxes, setTaxes] = useState({})
  const [transactionTaxes, setTransactionTaxes] = useState({
    totalPreTax: 0,
    totalTax: 0,
    totalPostTax: 0
  })

  useEffect(() => {
    const getTaxes = async () => {
      const countr = await AppInstances.getCountrSdk()
      const { taxes_breakdown } = countr.calculateTotal(props.transaction)
      return taxes_breakdown
    }
    const rec = props.devices.store.receipt
      ? props.devices.store.receipt
      : cartUtils.getDefaultReceipt(props.user.user)
    setReceipt(rec)
    setIsReceiptAvailable(true)

    getTaxes().then(t => {
      setTaxes(t)
      const taxKeys = Object.keys(t)

      if (taxKeys?.length) {
        const reduceCb = field =>
          taxKeys.reduce((acc, tax) => {
            return acc + t[tax][field]
          }, 0)

        const totalPreTax = reduceCb('preTax')
        const totalTax = reduceCb('tax')
        const totalPostTax = reduceCb('postTax')

        setTransactionTaxes({
          totalPreTax: totalPreTax,
          totalTax: totalTax,
          totalPostTax: totalPostTax
        })
      }
    })
  }, [])

  const getFontSize = textSize => {
    switch (textSize) {
      case 's':
        return 12
      case 'm':
        return 16
      case 'l':
        return 20
      default:
        return 16
    }
  }

  const lineFeed = () => {
    return (
      <Grid item xs={12}>
        &nbsp;
      </Grid>
    )
  }

  const addHorizontalLine = () => {
    return (
      <Grid item xs={12} className="receipt-line">
        &nbsp;
      </Grid>
    )
  }

  const getLogoHeader = headers => {
    const logo = headers.find(header => header.type === 'image')

    if (logo) {
      return (
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <img src={logo.content} className="receipt-logo" alt="Receipt logo" />
        </Grid>
      )
    }
  }

  const getReceiptHeaders = headers => {
    if (headers.length) {
      const head = headers.map(
        header =>
          header.type !== 'image' && (
            <Grid
              item
              xs={12}
              key={header.content}
              className="receipt-grid-container"
              style={{
                textAlign: header.style.alignment,
                fontSize: getFontSize(header.style.fontSize),
                fontWeight: header.style.fontWeight
              }}>
              <font>{header.content}</font>
            </Grid>
          )
      )

      return head
    }
  }

  const getTransactionEmployee = () => {
    const transaction = props.transaction

    if (transaction.employee && transaction.employee._id !== null) {
      return (
        <Grid item xs={12} className="receipt-grid-container top">
          <Grid container>
            <Grid item xs={2} className="left">
              <Text id="employeeid" />
            </Grid>
            <Grid item xs={10} className="right">
              {transaction.employee.name}
            </Grid>
          </Grid>
        </Grid>
      )
    }
  }

  const getTransactionCustomer = () => {
    const transaction = props.transaction

    if (transaction.customer) {
      return (
        <Grid item xs={12} className="receipt-grid-container">
          <Grid container>
            <Grid item xs={2} className="left">
              <Text id="customerid" />
            </Grid>
            <Grid item xs={10} className="right font-14">
              {getCustomerName(transaction.customer)}
            </Grid>
          </Grid>
        </Grid>
      )
    }
  }

  const getCustomerName = customer => {
    let name = ''
    name += customer.first_name ? customer.first_name + ' ' : ''
    name += customer.middle_name ? customer.middle_name : ''
    name += customer.last_name ? customer.last_name : ''
    name += ` (${customer.email})`

    return name
  }

  const getTransactionDate = () => {
    const transaction = props.transaction
    return (
      <Grid item xs={12} className="receipt-grid-container" style={{ textAlign: 'center' }}>
        <Grid container>
          <Grid item xs={12}>
            {`${new Date(transaction.date).toLocaleDateString('en-GB')} - ${new Date(
              transaction.date
            ).toLocaleTimeString('en-GB')}`}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const getTransactionReceiptId = () => {
    const transaction = props.transaction
    return (
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Text id="receipt" />: <strong>#{transaction.receipt_id}</strong>
      </Grid>
    )
  }

  const getCartName = () => {
    const transaction = props.transaction

    // If setting is turned off AND we don't have an eCommerce ID saved in extras then do nothing
    if (!props.settings.showCartNameInTransaction && !transaction?.extras?.posCartName) {
      return
    }

    return (
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <strong>{cartUtils.getCartName(transaction)}</strong>
      </Grid>
    )
  }

  const getTransactionItems = () => {
    const transaction = props.transaction

    return (
      <Grid item xs={12} className="receipt-grid-container">
        {transaction.items
          .filter(item => item.amount)
          .map((item, i) => (
            <Grid
              container
              key={`${cartUtils.getCartEntryId(item)}-${i}`}
              className="receipt-grid-item">
              <Grid item xs={8} className="left">
                {item.amount}
                {returnAmountType(item)}
                {ProductUtils.getProductName(item.product, props.settings.language)}
              </Grid>
              <Grid item xs={4} className="right">
                {returnItemprice(item)}
              </Grid>
              {returnVariant(item)}
              {returnBrand(item)}
              {returnUnitPrice(item)}
              {returnItemDeposit(item)}
              {returnAddons(item)}
              {returnNote(item)}
              {returnDiscountNote(item)}
              {returnEAN(item)}
              {returnQRTag(item) || returnNonRevenue(item)}
              {returnProductDescription(item)}
              {returnItemDiscount(item)}
            </Grid>
          ))}
      </Grid>
    )
  }

  const returnAmountType = item => {
    return item.product.options && item.product.options.sold_by_weight ? 'gr ' : ' x '
  }

  const returnVariant = item => {
    if (!item.product.current_variant.default) {
      return (
        <Grid item xs={12} className="left">
          <font className="receipt-small-subitem">
            {ProductUtils.getVariantName(item.product.current_variant, props.settings.language)}
          </font>
        </Grid>
      )
    }
  }

  const returnUnitPrice = item => {
    let price = item.product.current_variant.price

    if (item.product.options.deposit && item.product.options.deposit.price) {
      const deposit = item.product.options.deposit
      price -= deposit.price
    }
    return (
      <Grid item xs={12} className="left">
        <font className="receipt-small-subitem">{`@ ${
          props.transaction.currency.symbol
        } ${parseFloat(price).toFixed(2)}`}</font>
      </Grid>
    )
  }

  const returnItemprice = item => {
    // Checking with has negative discount (new price bigger than the current price)
    if (item.product.discount && item.product.discount < 0) {
      return `${props.transaction.currency.symbol} ${cartUtils.calculateItemPrice(item).toFixed(2)}`
    } else {
      return `${props.transaction.currency.symbol} ${cartUtils
        .calculateItemPriceNoDiscount(item)
        .toFixed(2)}`
    }
  }

  const returnItemDeposit = item => {
    const deposit = item.product.options.deposit
    if (deposit && deposit.price) {
      const line = `+ ${translate('deposit')} - ${
        props.transaction.currency.symbol
      }${deposit.price.toFixed(2)}`
      return (
        <Grid item xs={12} className="left">
          <font className="receipt-small-subitem">{line}</font>
        </Grid>
      )
    }
  }

  const returnEAN = item => {
    if (
      props.settings.showEANSKUReceipt &&
      item.product.current_variant.ean &&
      item.product.current_variant.ean.length
    ) {
      return (
        <Grid item xs={12} className="left">
          <font className="receipt-small-subitem">EAN: {item.product.current_variant.ean}</font>
        </Grid>
      )
    }
  }

  const returnBrand = item => {
    if (props.settings.showBrandReceipt && item.product?.brand) {
      return (
        <Grid item xs={12} className="left">
          <font className="receipt-small-subitem">{item.product.brand}</font>
        </Grid>
      )
    }
  }

  const returnNote = item => {
    if (item.note && item.note.length) {
      return (
        <Grid item xs={12} className="left">
          <font className="receipt-small-subitem">*** {item.note} ***</font>
        </Grid>
      )
    }
  }

  const returnDiscountNote = item => {
    if (Utils.cartEntryHasDiscountNote(item)) {
      return (
        <Grid item xs={12} className="left">
          <font className="receipt-small-subitem">{item.product.reduction.reason}</font>
        </Grid>
      )
    }
  }

  const returnNonRevenue = item => {
    if (
      item.product.extras &&
      item.product.extras.non_revenue_info &&
      item.product.extras.non_revenue_info.length
    ) {
      return (
        <Grid item xs={12} className="left">
          <font className="receipt-small-subitem">
            *** {item.product.extras.non_revenue_info} ***
          </font>
        </Grid>
      )
    }
  }

  const returnQRTag = item => {
    if (item.product.extras && item.product.extras.qr_tag && item.product.extras.qr_tag.length) {
      return (
        <Grid item xs={12} className="left">
          <font className="receipt-small-subitem">*** {item.product.extras.qr_tag} ***</font>
        </Grid>
      )
    }
  }

  const returnAddons = item => {
    if (item.product.current_addons.length) {
      return item.product.current_addons.map(addon => (
        <Grid item xs={12} key={addon._id} className="left">
          <font className="receipt-small-subitem">
            + {addon.amount} x {addon.name} ({props.transaction.currency.symbol}{' '}
            {addon.price.toFixed(2)})
          </font>
        </Grid>
      ))
    }
  }

  const returnProductDescription = item => {
    if (
      !!item.product.description ||
      ProductUtils.hasTranslatedDescription(item.product, props.settings.language)
    ) {
      return (
        <Grid item xs={12} className="left">
          <font className="receipt-small-subitem">
            {ProductUtils.getProductDescription(item.product, props.settings.language)}
          </font>
        </Grid>
      )
    }
  }

  const returnItemDiscount = item => {
    if (Utils.cartEntryHasDiscount(item)) {
      const discountPercent = cartUtils.getDiscountPercentFromItem(item.product)
      const discountAmount = Utils.calculateCartEntryDiscount(item)
      const textId = discountPercent < 0 ? 'surcharge' : 'discount'
      return (
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={8} className="left">
              <font className="receipt-small-subitem">
                <Text id={textId} />{' '}
                {item.product.reduction?.numeric
                  ? `${props.transaction.currency.symbol} ${item.product.reduction.numeric.toFixed(
                      2
                    )}`
                  : `${(Math.abs(discountPercent) * 100).toFixed(2)}%`}
              </font>
            </Grid>
            <Grid item xs={4} className="right">
              {discountPercent > 0 ? '-' : ''}
              {props.transaction.currency.symbol}{' '}
              {item.product.reduction?.numeric
                ? `${(item.product.reduction.numeric * item.amount).toFixed(2)}`
                : Math.abs(discountAmount).toFixed(2)}
            </Grid>
          </Grid>
        </Grid>
      )
    }
  }

  const returnDetailsItem = type => {
    const transaction = props.transaction
    if (transaction[type] !== undefined || type === 'tip') {
      let value = 0

      if (type === 'discount') {
        if (parseFloat(transaction.total) === 0) {
          value = `0.00 (${parseFloat(transaction.discount * 100).toFixed(2)}%)`
        } else if (transaction.discount && transaction.discount > 0) {
          value = parseFloat(
            (transaction.total * transaction.discount) / (1 - transaction.discount)
          ).toFixed(2)
        } else if (transaction.reduction && transaction.reduction.numeric) {
          value = parseFloat(transaction.reduction.numeric).toFixed(2)
        }
      } else if (type === 'tip') {
        value = parseFloat(PaymentUtils.calculatetransactionsTip(transaction)).toFixed(2)
        if (value <= 0) return
      } else {
        value = parseFloat(transaction[type]).toFixed(2)

        if (type === 'total' && !!transaction.extras.transactionFee) {
          value = (parseFloat(value) + transaction.extras.transactionFee).toFixed(2)
        }
      }

      if (
        type === 'change' &&
        PaymentUtils.calculatetransactionsTip(transaction) > 0 &&
        parseFloat(value) === 0
      ) {
        return
      }

      return (
        <Grid item xs={12} className="receipt-grid-container">
          <Grid container>
            <Grid item xs={8} className="left">
              {type === 'total' ? (
                <strong>
                  <Text id={type} />
                </strong>
              ) : (
                <Text id={type} />
              )}
              {type === 'discount' && transaction.discount > 0 && (
                <span>{` (${parseFloat(transaction.discount * 100).toFixed(2)}%)`}</span>
              )}
            </Grid>
            <Grid item xs={4} className="right">
              {type === 'total' ? (
                <strong>
                  {transaction.currency.symbol} {value}
                </strong>
              ) : (
                <font>
                  {transaction.currency.symbol} {type === 'discount' && value > 0 && '-'}
                  {value}
                </font>
              )}
            </Grid>
          </Grid>
        </Grid>
      )
    }
  }

  const returnGiveaway = () => {
    const transaction = props.transaction

    if (transaction.extras && transaction.extras.giveaway_reason) {
      const reason = transaction.extras.giveaway_reason

      if (reason !== null && reason !== '') {
        return (
          <Grid item xs={12} className="receipt-grid-container">
            <Grid container>
              <Grid item xs={6} className="left">
                <Text id="giveaway_reason" />
              </Grid>
              <Grid item xs={6} className="right">
                <strong>
                  <Text id={reason} />
                </strong>
              </Grid>
            </Grid>
          </Grid>
        )
      }
    } else if (transaction.reason && transaction.reason !== null) {
      return (
        <Grid item xs={12} className="receipt-grid-container">
          <Grid container>
            <Grid item xs={6} className="left">
              <Text id="reason" />
            </Grid>
            <Grid item xs={6} className="right">
              <strong>
                <Text id={transaction.reason} />
              </strong>
            </Grid>
          </Grid>
        </Grid>
      )
    }
  }

  const returnPaymentsDetails = () => {
    const transaction = props.transaction

    if (transaction.payments !== undefined && transaction.payments.length) {
      return transaction.payments.map((payment, index) => {
        // in case of tip the payment is 0 so we must hide it
        if (payment.paid === 0 && payment.tip > 0) return

        let method = !!translate(payment.method) ? translate(payment.method) : payment.method

        if (payment.provider && payment.provider !== null) {
          const providerTranslated = translate(payment.provider)
          method += ` - ${
            providerTranslated && providerTranslated !== null && providerTranslated.length
              ? providerTranslated
              : payment.provider
          }`
        }

        let paid = payment.paid

        if (!!transaction.extras.transactionFee && index === 0) {
          paid += transaction.extras.transactionFee
        }

        return (
          <Grid
            item
            xs={12}
            className="receipt-grid-container"
            key={`${payment.method}-${paid}-${index}`}>
            <Grid container>
              <Grid item xs={8} className="left">
                <Text id="paid" />{' '}
                <font
                  testid="receipt-payment-method"
                  style={{ textTransform: 'capitalize' }}
                  title={getPaymentInfo(payment)}>
                  ({method})
                </font>
              </Grid>
              <Grid item xs={4} className="right">
                {transaction.currency.symbol} {paid.toFixed(2)}
              </Grid>
            </Grid>
          </Grid>
        )
      })
    }
  }

  const getPaymentInfo = payment => {
    if (!!payment.card_print_info && !!payment.card_print_info.clientTicket) {
      return payment.card_print_info.clientTicket
    }

    if (payment.method === 'giftcard' && !!payment.info) {
      return `Giftcard: ${payment.info.code}`
    }

    return ''
  }

  const returnTotalTaxes = () => {
    const transaction = props.transaction

    return (
      <Grid item xs={12} className="receipt-grid-container">
        <Grid container>
          <Grid item xs={8} className="left">
            <Text id="taxes" />
          </Grid>
          <Grid item xs={4} className="right">
            {transaction.currency.symbol} {transactionTaxes.totalTax.toFixed(2)}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const returnTaxesTable = () => {
    const taxKeys = Object.keys(taxes)

    if (taxKeys.length) {
      const { currency } = props.transaction

      return (
        <Grid item xs={12} className="receipt-grid-container" style={{ textAlign: 'center' }}>
          {/* header */}
          <Grid container style={{ fontWeight: 'bold' }}>
            <Grid item xs={3}>
              <Text id="tax_rate" />
            </Grid>
            <Grid item xs={3}>
              <Text id="net" />
            </Grid>
            <Grid item xs={3}>
              <Text id="tax" />
            </Grid>
            <Grid item xs={3}>
              <Text id="total" />
            </Grid>
          </Grid>
          {/* lines */}
          {taxKeys.map(tax => (
            <Grid container key={tax}>
              <Grid item xs={3}>
                {tax}
              </Grid>
              <Grid item xs={3}>
                {currency.symbol} {taxes[tax].preTax.toFixed(2)}
              </Grid>
              <Grid item xs={3}>
                {currency.symbol} {taxes[tax].tax.toFixed(2)}
              </Grid>
              <Grid item xs={3}>
                {currency.symbol} {taxes[tax].postTax.toFixed(2)}
              </Grid>
            </Grid>
          ))}
          {/* Total */}
          <Grid container style={{ fontWeight: 'bold' }}>
            <Grid item xs={3}>
              <Text id="total" />
            </Grid>
            <Grid item xs={3}>
              {currency.symbol} {transactionTaxes.totalPreTax.toFixed(2)}
            </Grid>
            <Grid item xs={3}>
              {currency.symbol} {transactionTaxes.totalTax.toFixed(2)}
            </Grid>
            <Grid item xs={3}>
              {currency.symbol} {transactionTaxes.totalPostTax.toFixed(2)}
            </Grid>
          </Grid>
        </Grid>
      )
    }
  }

  const hasPrintTicket = () => {
    const transaction = props.transaction

    return (
      transaction.payments.findIndex(
        payment =>
          payment.card_print_info &&
          payment.card_print_info !== null &&
          payment.card_print_info.clientTicket
      ) >= 0
    )
  }

  const getCardPrintTicket = () => {
    const transaction = props.transaction

    return transaction.payments.map(
      (payment, index) =>
        payment.card_print_info &&
        payment.card_print_info !== null &&
        payment.card_print_info.clientTicket && (
          <Grid
            key={index}
            container
            justifyContent="space-between"
            alignItems="center"
            className="payplaza-print-content receipt-grid-container">
            <Grid item xs={12}>
              <span>{payment.card_print_info.clientTicket}</span>
            </Grid>
            {index < transaction.payments.length - 1 && (
              <Grid item xs={12} style={{ marginTop: 15 }}>
                <Divider />
              </Grid>
            )}
          </Grid>
        )
    )
  }

  const getRefundNote = () => {
    const transaction = props.transaction

    if (transaction.extras && transaction.extras.note && transaction.extras.note.length) {
      return (
        <Grid item xs={12} className="receipt-grid-container">
          <Grid container>
            <Grid item xs={6} className="left">
              <Text id="note" />
            </Grid>
            <Grid item xs={6} className="right">
              {transaction.extras.note}
            </Grid>
          </Grid>
        </Grid>
      )
    }
  }

  const getRefundSource = () => {
    const transaction = props.transaction

    if (transaction.receipt_source && transaction.receipt_source.length) {
      return (
        <Grid item xs={12} className="receipt-grid-container">
          <Grid container>
            <Grid item xs={6} className="left">
              <Text id="source_receipt_number" />
            </Grid>
            <Grid item xs={6} className="right">
              {transaction.receipt_source}
            </Grid>
          </Grid>
        </Grid>
      )
    }
  }

  const getTransactionSource = () => {
    const transaction = props.transaction

    if (transaction.refunds && transaction.refunds.length) {
      const refunds = props.transactions.refunds.filter(refund => {
        return transaction.refunds.indexOf(refund._id) >= 0
      })

      if (refunds && refunds.length) {
        return (
          <Grid item xs={12} className="receipt-grid-container">
            <Grid container>
              <Grid item xs={3} className="left">
                <Text id="refunds" />
              </Grid>
              <Grid item xs={9} className="right">
                {refunds.map(r => r.receipt_id).join(', ')}
              </Grid>
            </Grid>
          </Grid>
        )
      }
    }
  }

  const getExchanges = () => {
    const transaction = props.transaction

    if (transaction.exchanges && transaction.exchanges.length) {
      const exchanges = props.transactions.transactions.filter(ex => {
        return transaction.exchanges.indexOf(ex._id) >= 0
      })

      if (exchanges && exchanges.length) {
        return (
          <Grid item xs={12} className="receipt-grid-container">
            <Grid container>
              <Grid item xs={3} className="left">
                <Text id="exchange" />
              </Grid>
              <Grid item xs={9} className="right">
                {exchanges.map(r => r.receipt_id).join(', ')}
              </Grid>
            </Grid>
          </Grid>
        )
      }
    }
  }

  const getExchangeSource = () => {
    const transaction = props.transaction

    if (transaction.extras && transaction.extras.exchange_source) {
      const exchange = props.transactions.transactions.find(
        t => t._id === transaction.extras.exchange_source
      )

      if (exchange) {
        return (
          <Grid item xs={12} className="receipt-grid-container">
            <Grid container>
              <Grid item xs={4} className="left">
                <Text id="exchange_source" />
              </Grid>
              <Grid item xs={8} className="right">
                {exchange.receipt_id}
              </Grid>
            </Grid>
          </Grid>
        )
      }
    }
  }

  const hasDeliveryInfo = () => {
    const transaction = props.transaction

    return (
      (transaction.order_source !== 'pos' || transaction.order_source !== 'web_pos') &&
      transaction.extras &&
      transaction.extras.delivery
    )
  }

  const returnDeliveryInfo = () => {
    const { print } = props.transaction.extras.delivery

    if (print) {
      return (
        <Grid item xs={12} className="receipt-grid-container">
          <Grid item xs={12} className="center">
            <strong>
              <Text id="delivery_details" />
            </strong>
          </Grid>
          {Object.keys(print).map(printItem => (
            <Grid container key={printItem}>
              <Grid item xs={6} className="left">
                <Text id={printItem} />
              </Grid>
              <Grid item xs={6} className="right">
                {print[printItem]}
              </Grid>
            </Grid>
          ))}
        </Grid>
      )
    }
  }

  const returnDeliveryCost = () => {
    const { delivery } = props.transaction.extras

    if (!!delivery && !!delivery.deliveryCost) {
      const cost = cartUtils.getDeliveryCost(props.transaction)

      return (
        <Grid item xs={12} className="receipt-grid-container">
          <Grid container>
            <Grid item xs={4} className="left">
              <Text id="deliveryCost" />
            </Grid>
            <Grid item xs={8} className="right">
              {`${props.transaction.currency.symbol} ${parseFloat(cost).toFixed(2)}`}
            </Grid>
          </Grid>
        </Grid>
      )
    }
  }

  const returnTransactionFee = () => {
    const { transactionFee } = props.transaction.extras

    if (!!transactionFee) {
      return (
        <Grid item xs={12} className="receipt-grid-container">
          <Grid container>
            <Grid item xs={4} className="left">
              <Text id="transactionFee" />
            </Grid>
            <Grid item xs={8} className="right">
              {`${props.transaction.currency.symbol} ${parseFloat(transactionFee).toFixed(2)}`}
            </Grid>
          </Grid>
        </Grid>
      )
    }
  }

  const getFiscalInfo = () => {
    const { fiscal_info } = props.transaction

    if (!!fiscal_info) {
      return (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className="payplaza-print-content receipt-grid-container">
          <Grid item xs={12} className="left">
            <span className="main-menu-app-version">{fiscal_info.formattedPrint}</span>
          </Grid>
        </Grid>
      )
    }
  }

  const isReprint = () => {
    const { issued } = props.transaction

    if (issued && issued > 1) {
      return (
        <Grid item xs={12} className="receipt-grid-container top">
          *** REPRINT ***
        </Grid>
      )
    }
  }

  return (
    <div>
      {isReceiptAvailable && (
        <Grid
          container
          className="receipt-container"
          justifyContent="center"
          alignItems="center"
          id="receipt">
          {(!props.transactionComplete || props.settings.showFullReceiptAfterPayment) &&
            getLogoHeader(receipt.header)}
          {(!props.transactionComplete || props.settings.showFullReceiptAfterPayment) &&
            getReceiptHeaders(receipt.header)}
          {(!props.transactionComplete || props.settings.showFullReceiptAfterPayment) &&
            addHorizontalLine()}
          {getTransactionDate()}
          {getTransactionReceiptId()}
          {getCartName()}
          {(!props.transactionComplete || props.settings.showFullReceiptAfterPayment) &&
            getTransactionEmployee()}
          {(!props.transactionComplete || props.settings.showFullReceiptAfterPayment) &&
            getTransactionCustomer()}
          {addHorizontalLine()}
          {(!props.transactionComplete || props.settings.showFullReceiptAfterPayment) &&
            getTransactionItems()}
          {(!props.transactionComplete || props.settings.showFullReceiptAfterPayment) &&
            addHorizontalLine()}
          {(props.transaction.discount && props.transaction.discount > 0) ||
          (props.transaction.reduction && props.transaction.reduction.numeric)
            ? returnDetailsItem('discount')
            : ''}
          {(!props.transactionComplete || props.settings.showFullReceiptAfterPayment) &&
            returnDeliveryCost()}
          {(!props.transactionComplete || props.settings.showFullReceiptAfterPayment) &&
            returnDetailsItem('sub_total')}
          {(!props.transactionComplete || props.settings.showFullReceiptAfterPayment) &&
            returnTotalTaxes()}
          {(!props.transactionComplete || props.settings.showFullReceiptAfterPayment) &&
            returnTransactionFee()}
          {returnDetailsItem('total')}
          {lineFeed()}

          {returnPaymentsDetails()}
          {returnDetailsItem('change')}
          {returnDetailsItem('tip')}
          {lineFeed()}
          {returnGiveaway()}

          {props.transaction.__t === TRANSACTION && getTransactionSource()}
          {props.transaction.__t === REFUND && getRefundNote()}
          {props.transaction.__t === REFUND && getRefundSource()}
          {props.transaction.__t === TRANSACTION && getExchanges()}
          {props.transaction.__t === TRANSACTION && getExchangeSource()}

          {lineFeed()}
          {(!props.transactionComplete || props.settings.showFullReceiptAfterPayment) &&
            returnTaxesTable()}
          {(!props.transactionComplete || props.settings.showFullReceiptAfterPayment) &&
            addHorizontalLine()}
          {hasDeliveryInfo() && returnDeliveryInfo()}
          {hasDeliveryInfo() && addHorizontalLine()}
          {(!props.transactionComplete || props.settings.showFullReceiptAfterPayment) &&
            getCardPrintTicket()}
          {(!props.transactionComplete || props.settings.showFullReceiptAfterPayment) &&
            hasPrintTicket() &&
            addHorizontalLine()}
          {getFiscalInfo()}
          {(!props.transactionComplete || props.settings.showFullReceiptAfterPayment) &&
            getReceiptHeaders(receipt.footer)}
          {isReprint()}
        </Grid>
      )}
    </div>
  )
})

const ReceiptConnected = connect(mapStateToProps, null)(Receipt)
export default ReceiptConnected
