import { PureComponent } from 'react'
import { connect } from 'react-redux'

import ScreenUtils from '../../../utils/ScreenUtils'
import { selectCart } from '../../../store/actions/carts'
import { cartUtils } from '../../../utils/cartUtils'

import Popover from '@material-ui/core/Popover'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { Text, translate } from 'react-internationalization'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

import './CartHeader.scss'

const mapStateToProps = state => {
  return {
    carts: state.carts,
    settings: state.settings
  }
}

const mapDispatchToProps = dispatch => {
  return {
    selectCart: cart => dispatch(selectCart(cart))
  }
}

class CartSelectionPopover extends PureComponent {
  state = {
    anchor: document.getElementById('cart-selector'),
    carts: []
  }

  handleCloseCartSelectionPopover = id => {
    this.setState({ anchor: null })
    this.props.handleCloseCartSelectionPopover(id)
  }

  handleChangeSelectedCart = id => {
    if (id === 'new_cart') {
      this.handleCloseCartSelectionPopover(id)
    } else if (id === 'delete_all') {
      this.handleCloseCartSelectionPopover(id)
    } else {
      const index = this.props.carts.carts.findIndex(c => c._id === id)
      const cart = this.props.carts.carts[index]
      this.props.selectCart(cart)
      if (this.props.settings.enableCustomerScreen) {
        const port = this.props.settings.customerScreenPort
        if (port && port.length) {
          ScreenUtils.changeCart(cart, port)
        }
      }
      localStorage.setItem('CountrLite:CurrentCart', JSON.stringify(cart))
      this.handleCloseCartSelectionPopover()
    }
  }

  sortAlphabeticalByField(obj) {
    return obj.sort((a, b) => {
      if (a.extras && a.extras.deviceCartName && b.extras && b.extras.deviceCartName) {
        return a.extras.deviceCartName.localeCompare(b.extras.deviceCartName)
      } else {
        return false
      }
    })
  }

  componentDidMount = () => {
    // TODO should we add the store/account location lang?
    TimeAgo.addLocale(en)
    const el = document.getElementById('cart-selector')
    this.setState({
      anchor: el,
      carts: this.props.settings.showCartsListAlphabeticOrder
        ? this.sortAlphabeticalByField(this.props.carts.carts)
        : this.props.settings?.keepSingleCart
          ? [
              this.props.carts.selectedCart,
              ...this.props.carts.carts.filter(c => {
                console.log('🚀 ~ CartSelectionPopover ~ c:', c)
                return c._id !== this.props.carts.selectedCart._id && c.items.length
              })
            ]
          : this.props.carts.carts
    })
  }

  returnCartListItem = cart => {
    const name = cartUtils.getCartName(cart)
    const total = `${cart.currency.symbol} ${cartUtils.getCartTotal(cart)}`
    const time = new TimeAgo('nl').format(new Date(cart.updated_at))

    return (
      <ListItemText
        primary={`${name} - ${total}`}
        secondary={`${translate('last_update')}: ${time}`}
      />
    )
  }

  render() {
    return (
      <Popover
        open={Boolean(this.props.openCartSelectionPopover)}
        anchorEl={this.state.anchor}
        onClose={this.handleCloseCartSelectionPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <List component="nav" className="selection-cart-list">
          {this.state.carts.map((cart, i) => (
            <ListItem
              button
              key={`${cart._id}_${i}`}
              divider={true}
              style={{
                padding: '6px 12px 6px 12px',
                backgroundColor:
                  this.props.carts.selectedCart._id === cart._id ? 'rgba(0, 0, 0, 0.2)' : ''
              }}
              onClick={() => this.handleChangeSelectedCart(cart._id)}>
              {this.returnCartListItem(cart)}
            </ListItem>
          ))}
          <ListItem
            button
            className="selection-cart-new"
            onClick={() => this.handleChangeSelectedCart('new_cart')}>
            <ListItemText
              className="selection-cart-new-text"
              disableTypography={true}
              primary={<Text id={this.props.settings.cartsTable ? 'new_table' : 'new_cart'} />}
            />
          </ListItem>
        </List>
      </Popover>
    )
  }
}

const CartSelectionPopoverConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(CartSelectionPopover)
export default CartSelectionPopoverConnected
