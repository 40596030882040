import { memo, useState } from 'react';
import { Text } from 'react-internationalization'
import ConfirmationModal from './../../generic/ConfirmationModal'
import './CustomerInfo.scss'

const CustomerInfo = memo(({ cart, update }) => {
  const [showConfirmation, setShowConfirmation] = useState(false)

  const returnCustomerName = () => {
    const customer = cart.customer
    return !!customer
      ? `${customer.first_name}${customer.middle_name ? ' ' + customer.middle_name : ''} ${customer.last_name} (${customer.email})`
      : ''
  }

  const remove = async () => {
    const copy = { ...cart }
    copy.customer = null
    update(copy)
  }

  return (
    <>
      <div className="customer-info" onClick={() => setShowConfirmation(true)}>
        <span className="icon-profile-loyalty icon" />
        <span className="text">{returnCustomerName()}</span>
      </div>
      {showConfirmation && (
        <ConfirmationModal
          openConfirmation={showConfirmation}
          handleCloseConfirmation={() => setShowConfirmation(false)}
          confirmBtn={remove}
          confirmationTitle={<Text id="remove_customer" />}
          confirmationText={<Text id="remove_customer_question" />}
          type="remove_customer"
        />
      )}
    </>
  )
})

export default CustomerInfo
