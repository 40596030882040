import { translate } from 'react-internationalization'

import { AppInstances } from './utils/countrSdkInstance'
import { cartUtils } from './utils/cartUtils'
import { returnUpdatableDevice } from './utils/DeviceUtils'
import StoreUtils from './utils/StoreUtils'
import WorkerResourceLoader from './WorkerResourceLoader'

import store from './index'
import { setLoadingMsg, setLoadingValue } from './store/actions/loading'
import { setProductCount } from './store/actions/products'
import { addAllCategory } from './store/actions/categories'
import { addAllTax } from './store/actions/taxes'
import { addAllTransactions, addAllRefunds } from './store/actions/transactions'
import { addAllCustomers } from './store/actions/customers'
import { addAllEmployees } from './store/actions/employees'
import { addDevice, addStore } from './store/actions/devices'
import { addCart, selectCart } from './store/actions/carts'

// Products
const PRODUCTS_ADDED = 'PRODUCTS_ADDED'
// Categories
const CATEGORIES_ADDED = 'CATEGORIES_ADDED'
const CATEGORIES_NOT_ADDED = 'CATEGORIES_NOT_ADDED'
// Taxes
const TAXES_ADDED = 'TAXES_ADDED'
const TAXES_NOT_ADDED = 'TAXES_NOT_ADDED'
// Customers
const CUSTOMERS_ADDED = 'CUSTOMERS_ADDED'
const CUSTOMERS_NOT_ADDED = 'CUSTOMERS_NOT_ADDED'
const CUSTOMER_LIMIT = 1000
// Employees
const EMPLOYEES_ADDED = 'EMPLOYEES_ADDED'
const EMPLOYEES_NOT_ADDED = 'EMPLOYEES_NOT_ADDED'
// Carts
const NO_CURRENT_CART = 'NO_CURRENT_CART'
const CARTS_ADDED = 'CARTS_ADDED'
const CARTS_NOT_ADDED = 'CARTS_NOT_ADDED'
// Transactions
const TRANSACTIONS_ADDED = 'TRANSACTIONS_ADDED'
const TRANSACTIONS_NOT_ADDED = 'TRANSACTIONS_NOT_ADDED'
// Refunds
const REFUNDS_ADDED = 'REFUNDS_ADDED'
const REFUNDS_NOT_ADDED = 'REFUNDS_NOT_ADDED'
// Device
const ADD_DEVICE = 'ADD_DEVICE'

/**
 * Start data load to feed Redux and IndexedDB
 */
class ResourceLoader extends WorkerResourceLoader {
  socket = null
  value = 0
  userId = null
  user = {}
  device = {}
  limit = process.env.REACT_APP_START_LIMIT

  constructor(user, device) {
    super(user, device)

    this.user = user
    this.device = device

    return (async () => {
      this.userId = user._id

      this.socket = await AppInstances.getCountrSdk()

      return this
    })()
  }

  logError = async obj => {
    const errorObj = {
      source: process.env.REACT_APP_ERROR_SOURCE,
      message: `${obj.msg}, users: ${this.user.username}, 
        _ID: ${this.user._id}, device id: ${this.device._id}`,
      user: this.user._id,
      store: this.device?.store?._id,
      device: this.device._id,
      stack: obj.stack,
      date: new Date().toISOString()
    }

    await AppInstances.getCountrSdk()
    AppInstances.logError(errorObj)
  }

  // Load all visible products to redux
  loadProducts = async storeId => {
    const count = await this.socket.stores.readOne(storeId + '/products/count')
    store.dispatch(setProductCount(count))
    // Products will be download via webworker
    store.dispatch(setLoadingMsg(translate('downloading_products')))
    store.dispatch(setLoadingValue((this.value += 10)))
    this.value += 100 / 8
    store.dispatch(setLoadingValue(this.value))
    return Promise.resolve(PRODUCTS_ADDED)
  }

  // Load all categories to redux
  loadCategories = async storeId => {
    const categories = await WorkerResourceLoader.getCategories().catch(error => {
      console.log(`${CATEGORIES_NOT_ADDED}: ${JSON.stringify(error)}`)
      return Promise.reject(`${CATEGORIES_NOT_ADDED}: ${JSON.stringify(error)}`)
    })

    if (!categories.length) {
      const count = await this.socket.stores.readOne(storeId + '/categories/count')

      const promises = []
      let counter = 0
      const errorsArray = []

      while (counter < count) {
        promises.push(
          this.socket.stores
            .readOne(storeId + '/categories', {
              limit: process.env.REACT_APP_START_LIMIT,
              skip: counter
            })
            .then(
              categories => {
                this.value += 5
                store.dispatch(setLoadingMsg(translate('downloading_categories')))
                store.dispatch(addAllCategory(categories))
                store.dispatch(setLoadingValue(this.value))
                return Promise.resolve(CATEGORIES_ADDED)
              },
              error => {
                console.log(`${CATEGORIES_NOT_ADDED}: ${JSON.stringify(error)}`)
                errorsArray.push(`${CATEGORIES_NOT_ADDED}: ${JSON.stringify(error)}`)
                return Promise.reject(`${CATEGORIES_NOT_ADDED}: ${JSON.stringify(error)}`)
              }
            )
        )

        counter += process.env.REACT_APP_START_LIMIT
      }

      return Promise.all(promises.map(this.reflect)).then(results => {
        const errors = results.filter(x => x.status === 'rejected')

        if (errors.length === 0) {
          this.value += 100 / 8
          store.dispatch(setLoadingValue(this.value))
          return Promise.resolve(CATEGORIES_ADDED)
        } else {
          return Promise.reject(`${CATEGORIES_NOT_ADDED}: ${JSON.stringify(errorsArray)}`)
        }
      })
    }

    this.value += 10
    store.dispatch(setLoadingMsg(translate('downloading_categories')))
    store.dispatch(addAllCategory(categories))
    store.dispatch(setLoadingValue(this.value))

    return Promise.resolve(CATEGORIES_ADDED)
  }

  // Load all taxes to redux
  loadTaxes = () => {
    return this.socket.taxes.read().then(
      taxes => {
        this.value += 10
        store.dispatch(setLoadingMsg(translate('downloading_taxes')))
        store.dispatch(addAllTax(taxes))
        store.dispatch(setLoadingValue(this.value))
        return Promise.resolve(TAXES_ADDED)
      },
      error => {
        console.log(`${TAXES_NOT_ADDED}: ${error}`)
        return Promise.reject(`${TAXES_NOT_ADDED}: ${error}`)
      }
    )
  }

  // Load last week transactions to redux
  loadTransactions = storeId => {
    return this.socket.transactions.read({ limit: 50, sort: '-created_at' }).then(
      transactions => {
        store.dispatch(setLoadingMsg(translate('downloading_transactions')))
        const filterTransactions = transactions.filter(t => t.store === storeId)
        this.value += 10
        store.dispatch(addAllTransactions(filterTransactions))
        store.dispatch(setLoadingValue(this.value))
        return Promise.resolve(TRANSACTIONS_ADDED)
      },
      error => {
        console.log(`${TRANSACTIONS_NOT_ADDED}: ${error}`)
        return Promise.reject(`${TRANSACTIONS_NOT_ADDED}: ${error}`)
      }
    )
  }

  // Load last week transactions to redux
  loadRefunds = storeId => {
    return this.socket.refunds.read({ limit: 50, sort: '-created_at' }).then(
      refunds => {
        store.dispatch(setLoadingMsg(translate('downloading_refunds')))
        const filterRefunds = refunds.filter(r => r.store === storeId)
        this.value += 10
        store.dispatch(addAllRefunds(filterRefunds))
        store.dispatch(setLoadingValue(this.value))
        return Promise.resolve(REFUNDS_ADDED)
      },
      error => {
        console.log(`${REFUNDS_NOT_ADDED}: ${error}`)
        return Promise.reject(`${REFUNDS_NOT_ADDED}: ${error}`)
      }
    )
  }

  // Load all customers to redux
  loadCustomers = () => {
    return this.socket.customers.read({ limit: CUSTOMER_LIMIT }).then(
      customers => {
        this.value += 10
        store.dispatch(setLoadingMsg(translate('downloading_customers')))
        store.dispatch(addAllCustomers(customers))
        store.dispatch(setLoadingValue(this.value))
        return Promise.resolve(CUSTOMERS_ADDED)
      },
      error => {
        console.log(`${CUSTOMERS_NOT_ADDED}: ${error}`)
        return Promise.reject(`${CUSTOMERS_NOT_ADDED}: ${error}`)
      }
    )
  }

  // Load all employees
  loadEmployees = deviceStore => {
    return this.socket.stores.readOne.detailed(deviceStore._id).then(
      storeWithEmployees => {
        const employees = !!storeWithEmployees.employees ? storeWithEmployees.employees : []
        this.value += 10
        store.dispatch(setLoadingMsg(translate('downloading_employees')))
        store.dispatch(addAllEmployees(employees))
        store.dispatch(setLoadingValue(this.value))
        return Promise.resolve(EMPLOYEES_ADDED)
      },
      error => {
        console.log(`${EMPLOYEES_NOT_ADDED}: ${error}`)
        return Promise.reject(`${EMPLOYEES_NOT_ADDED}: ${error}`)
      }
    )
  }

  // Load the current registered device and store to redux
  loadDevice = async (device, deviceStore) => {
    store.dispatch(addDevice(device))
    store.dispatch(addStore(deviceStore))
    return Promise.resolve(ADD_DEVICE)
  }

  // Load current cart of localstorage to redux
  // This is the only promise that can fail
  // If fails, get the last updated cart that is in redux
  loadCurrentCart = async () => {
    let local = JSON.parse(localStorage.getItem('CountrLite:CurrentCart'))

    // Small defensive check, searching if the localstorage cart
    // still available in our database
    let cartExistServerSide = null
    if (!!local && local !== 'undefined' && local?._id) {
      cartExistServerSide = await this.socket.carts.readOne(local._id)
      if (!cartUtils.checkCartProps(local)) {
        local = cartExistServerSide
      }
    }

    if (cartExistServerSide) {
      const currentCart =
        cartExistServerSide.updated_at >= local.updated_at ? cartExistServerSide : local
      store.dispatch(selectCart(currentCart))
      localStorage.setItem('CountrLite:CurrentCart', JSON.stringify(currentCart))
      return Promise.resolve(currentCart)
    } else {
      const retry = this.getLastUpdatedCart()

      // Same defensive check for server side existence
      let retryExistServerSide = null
      if (retry) {
        retryExistServerSide = await this.socket.carts.readOne(retry._id)
        console.log(
          '🚀 ~ file: ResourceLoader.js:287 ~ ResourceLoader ~ loadCurrentCart= ~ retryExistServerSide:',
          retryExistServerSide
        )
      }

      if (retryExistServerSide) {
        const currentCartRetry =
          retryExistServerSide.updated_at > retry.updated_at ? retryExistServerSide : retry
        store.dispatch(selectCart(currentCartRetry))
        localStorage.setItem('CountrLite:CurrentCart', JSON.stringify(currentCartRetry))
        return Promise.resolve(currentCartRetry)
      } else {
        const newCartIndex = await cartUtils.getLatestReceiptId()
        if (newCartIndex) {
          const createdCart = cartUtils.createCart(this.userId, this.device, newCartIndex)
          createdCart.extras.deviceCartName = 'New Cart'
          const createdCartServer = await cartUtils.createCartServer(createdCart)

          store.dispatch(selectCart(createdCartServer))
          localStorage.setItem('CountrLite:CurrentCart', JSON.stringify(createdCartServer))
          return Promise.resolve(createdCartServer)
        } else {
          return Promise.reject(NO_CURRENT_CART)
        }
      }
    }
  }

  getLastUpdatedCart = () => {
    const cartsKeys = []
    let lastUpdatedCart = {}
    let aux = {}

    // getting all carts keys in localstorage
    for (const key in localStorage) {
      if (key.indexOf('CountrLite:Cart-') >= 0) {
        cartsKeys.push(key)
      }
    }

    if (cartsKeys.length) {
      cartsKeys.forEach((cartKey, index) => {
        aux = JSON.parse(localStorage.getItem(cartKey))

        if (index === 0) {
          lastUpdatedCart = aux
        } else {
          if (lastUpdatedCart.updated_at < aux.updated_at) {
            lastUpdatedCart = aux
          }
        }
      })

      return lastUpdatedCart
    }
  }

  // Load all carts of localstorage to redux
  loadCarts = device => {
    const cartsKeys = []
    const hasFloorplans = !!device.store.floorplans && !!device.store.floorplans.length

    // getting all carts keys in localstorage
    for (const key in localStorage) {
      if (key.indexOf('CountrLite:Cart-') >= 0) {
        cartsKeys.push(key)
      }
    }

    if (cartsKeys.length) {
      if (JSON.parse(localStorage.getItem(cartsKeys[0])).store !== device.store._id) {
        cartsKeys.forEach(cartKey => {
          localStorage.removeItem(cartKey)
          localStorage.removeItem('CountrLite:CurrentCart')
        })
        return Promise.reject(CARTS_NOT_ADDED)
      } else {
        const promises = []
        store.dispatch(setLoadingMsg(translate('downloading_carts')))

        cartsKeys.forEach(cartKey => {
          const localCart = JSON.parse(localStorage.getItem(cartKey))

          if (localCart._id) {
            promises.push(
              this.socket.carts.readOne(localCart._id).then(
                cartServer => {
                  const savedCart =
                    cartServer.updated_at >= localCart.updated_at ? cartServer : localCart

                  // Updating cart name to be the same as the table name
                  if (hasFloorplans) {
                    let table = null
                    device.store.floorplans.forEach(floor => {
                      table = floor.tables.find(t => t.linked_cart === savedCart._id)
                    })

                    if (!!table && !!table.name) {
                      savedCart.extras = {
                        ...savedCart.extras,
                        deviceCartName: table.name
                      }
                    }
                  }

                  store.dispatch(addCart(savedCart))
                  localStorage.setItem(
                    'CountrLite:Cart-' + savedCart._id,
                    JSON.stringify(savedCart)
                  )

                  this.value += cartsKeys.length / 4
                  store.dispatch(setLoadingValue(this.value))
                  return Promise.resolve(CARTS_ADDED)
                },
                error => {
                  console.log('🚀 ~ file: ResourceLoader.js:400 ~ ResourceLoader ~ error:', error)
                  localStorage.removeItem('CountrLite:Cart-' + localCart._id)
                  return Promise.reject(CARTS_NOT_ADDED)
                }
              )
            )
          } else {
            localStorage.removeItem(cartKey)
          }
        })

        return Promise.all(promises.map(this.reflect)).then(results => {
          const success = results.filter(x => x.status === 'resolved')

          if (success.length) {
            return Promise.resolve(CARTS_ADDED)
          } else {
            return Promise.reject(CARTS_NOT_ADDED)
          }
        })
      }
    } else {
      return Promise.reject(CARTS_NOT_ADDED)
    }
  }

  // Load all resources and enqueue it as a Promise
  loadAllResources = device => {
    this.value = 0
    const resources = []
    const deviceStore = device.store

    resources.push(this.loadProducts(deviceStore._id))
    resources.push(this.loadCategories(deviceStore._id))
    resources.push(this.loadTaxes())
    resources.push(this.loadTransactions(deviceStore._id))
    resources.push(this.loadRefunds(deviceStore._id))
    resources.push(this.loadCustomers())
    resources.push(this.loadDevice(device, deviceStore))
    resources.push(this.loadCarts(device))
    resources.push(this.loadCurrentCart())
    resources.push(this.loadEmployees(deviceStore))

    return Promise.all(resources.map(this.reflect)).then(results => {
      const success = results.filter(x => x.status === 'resolved')
      const errors = results.filter(x => x.status === 'rejected')

      if (errors.length > 0) {
        return this.threatErrors(errors, device).then(
          success => {
            store.dispatch(setLoadingValue(undefined))
            return Promise.resolve(success)
          },
          error => {
            console.log('########### ERROR RESOURCE LOADER ###########')
            store.dispatch(setLoadingValue(undefined))

            const finalError = !!error && !!error.length ? errors.concat(error) : errors

            return Promise.reject(finalError)
          }
        )
      } else {
        store.dispatch(setLoadingValue(undefined))
        return Promise.resolve(success)
      }
    })
  }

  reflect = promise => {
    return promise.then(
      v => {
        return { result: v, status: 'resolved' }
      },
      e => {
        return { error: e, status: 'rejected' }
      }
    )
  }

  threatErrors = (errors, device) => {
    const noCartsIndex = errors.findIndex(e => e.error === CARTS_NOT_ADDED)

    if (noCartsIndex >= 0) {
      store.dispatch(setLoadingValue(90))

      if (StoreUtils.hasFloorplans(device.store)) {
        return this.recoveryTables(device).then(
          success => {
            return Promise.resolve(success)
          },
          error => {
            return Promise.reject(error)
          }
        )
      } else {
        return this.createCarts(device).then(
          success => {
            return Promise.resolve(success)
          },
          error => {
            return Promise.reject(CARTS_NOT_ADDED)
          }
        )
      }
    } else {
      // No cart error
      return Promise.reject(errors)
    }
  }

  recoveryTables = device => {
    if (device.store.floorplans.length > 0) {
      let lastUpdatedTable = {}
      const promises = []
      let updateFloorplan = false

      device.store.floorplans.forEach((floor, floorIndex) => {
        if (!StoreUtils.floorplanHasTables(floor)) {
          promises.push(
            Promise.reject(
              `Floorplan ${floor.name} doesn't have tables,
               please make sure to add tables to this floorplan before use the WEB POS`
            )
          )
        } else {
          floor.tables.forEach((table, index) => {
            store.dispatch(setLoadingMsg(`Loading tables (${index + 1}/${floor.tables.length})`))
            this.value += 2
            store.dispatch(setLoadingValue(this.value))

            if (table.linked_cart !== undefined) {
              promises.push(
                this.socket.carts.readOne(table.linked_cart).then(cart => {
                  if (index === 0) {
                    lastUpdatedTable = cart
                  } else {
                    if (cart.updated_at > lastUpdatedTable.updated_at) {
                      lastUpdatedTable = cart
                    }
                  }
                  store.dispatch(addCart(cart))
                  localStorage.setItem('CountrLite:Cart-' + cart._id, JSON.stringify(cart))
                })
              )
            } else {
              updateFloorplan = true
              let cart = {}
              cart = cartUtils.createCart(this.userId, device, index)
              cart.extras.deviceCartName = table.name

              promises.push(
                cartUtils.createCartServer(cart).then(tableCart => {
                  store.dispatch(addCart(tableCart))
                  localStorage.setItem(
                    'CountrLite:Cart-' + tableCart._id,
                    JSON.stringify(tableCart)
                  )
                  if (index === 0) {
                    lastUpdatedTable = tableCart
                  } else {
                    if (tableCart.updated_at > lastUpdatedTable.updated_at) {
                      lastUpdatedTable = tableCart
                    }
                  }

                  // Linking cart id with the table
                  device.store.floorplans[floorIndex].tables[index].linked_cart = tableCart._id
                })
              )
            }
          })
        }
      })

      if (updateFloorplan) {
        promises.push(
          this.socket.devices
            .update(device._id, returnUpdatableDevice(device))
            .then(updatedDevice => {
              console.log('device updated')
            })
        )
        promises.push(
          this.socket.stores.update(device.store._id, device.store).then(updatedStore => {
            console.log('store updated')
          })
        )
      }

      return Promise.all(promises.map(this.reflect)).then(results => {
        const success = results.filter(x => x.status === 'resolved')
        const errors = results.filter(x => x.status === 'rejected')

        if (success.length >= 1) {
          store.dispatch(selectCart(lastUpdatedTable))
          localStorage.setItem('CountrLite:CurrentCart', JSON.stringify(lastUpdatedTable))
          return Promise.resolve(CARTS_ADDED)
        } else {
          return Promise.reject(errors)
        }
      })
    } else {
      return this.createCarts(device).then(
        success => {
          return Promise.resolve(success)
        },
        () => {
          return Promise.reject(CARTS_NOT_ADDED)
        }
      )
    }
  }

  // TODO here is where we going to add the logic for the single cart per store, and reusing
  createCarts = device => {
    // const MAX_CARTS = device.settings?.web_settings?.keepSingleCart ? 1 : 5
    const MAX_CARTS = 5

    const MAX_LOAD_CARTS = 100
    const promises = []
    let hasCartsLoaded = false

    // Checking if has some old carts with items, if it has, append it to the new carts array
    promises.push(
      this.socket.carts.read({ limit: MAX_LOAD_CARTS, sort: '-updated_at' }).then(carts => {
        let oldCartsWithItems = []
        // Check if has carts with items for this device
        oldCartsWithItems = carts.filter(
          cart =>
            cart.items.length > 0 && cart.store === device.store._id && cart.device === device._id
        )
        let cartsWithoutItems = []
        // Check if has carts without items for this device
        cartsWithoutItems = carts.filter(
          cart =>
            cart.items.length === 0 && cart.store === device.store._id && cart.device === device._id
        )
        if (oldCartsWithItems.length > 0) {
          oldCartsWithItems.forEach((cart, index) => {
            store.dispatch(
              setLoadingMsg(`Loading old carts (${index}/${oldCartsWithItems.length})`)
            )
            localStorage.setItem('CountrLite:Cart-' + cart._id, JSON.stringify(cart))
            store.dispatch(addCart(cart))
          })
          hasCartsLoaded = true
        }
        if (cartsWithoutItems.length > 0) {
          const empytCartsNum =
            oldCartsWithItems.length >= MAX_CARTS ? 1 : MAX_CARTS - oldCartsWithItems.length
          cartsWithoutItems.splice(0, empytCartsNum).forEach((cart, index) => {
            store.dispatch(
              setLoadingMsg(`Loading old carts (${index}/${oldCartsWithItems.length})`)
            )
            localStorage.setItem('CountrLite:Cart-' + cart._id, JSON.stringify(cart))
            store.dispatch(addCart(cart))
          })
          hasCartsLoaded = true
        }
      })
    )

    return Promise.all(promises.map(this.reflect)).then(async results => {
      let success = results.filter(x => x.status === 'resolved')
      if (!hasCartsLoaded) {
        success = await this.createNewCarts(device, addCart)
      }

      if (success.length >= 1) {
        const last = this.getLastUpdatedCart()
        store.dispatch(selectCart(last))
        localStorage.setItem('CountrLite:CurrentCart', JSON.stringify(last))
        return Promise.resolve(CARTS_ADDED)
      } else {
        return Promise.reject(CARTS_NOT_ADDED)
      }
    })
  }

  createNewCarts = device => {
    const MAX_CARTS = 5
    const promises = []

    for (let i = 1; i <= MAX_CARTS; i++) {
      const cart = cartUtils.createCart(this.userId, device, i)
      promises.push(
        cartUtils.createCartServer(cart).then(created => {
          localStorage.setItem('CountrLite:Cart-' + created._id, JSON.stringify(created))
          store.dispatch(addCart(created))
        })
      )
    }

    return Promise.all(promises.map(this.reflect)).then(
      result => {
        return Promise.resolve(result)
      },
      error => {
        return Promise.reject(error)
      }
    )
  }
}

export default ResourceLoader
